enum FETCH_STATUS {
  OK = 'ok',
  ERROR = 'error',
  LOADING = 'loading',
  NEVER = 'never'
}

const emptyResponseWithStatus = <T>(): ResponseWithStatus<T> => ({
  response: undefined,
  status: FETCH_STATUS.NEVER
})

export {
  // Actions
  emptyResponseWithStatus,
  // Enums
  FETCH_STATUS
}
