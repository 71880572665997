export const TokenContracts = {
  SCREAM: {
    address: '0xe0654C8e6fd4D733349ac7E09f6f23DA256bF475'
  },
  BOO: {
    address: '0x841fad6eae12c286d1fd18d1d525dffa75c7effe'
  },
  SPIRIT: {
    address: '0x5cc61a78f164885776aa610fb0fe1257df78e59b'
  },
  BEETS: {
    address: '0xf24bcf4d1e507740041c9cfd2dddb29585adce1e'
  },
  fBEETS: {
    address: '0xfcef8a994209d6916eb2c86cdd2afd60aa6f54b1'
  }
}

export default TokenContracts
