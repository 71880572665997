import BigNumberJs from 'bignumber.js'
import { Contract, ethers } from 'ethers'
import { defineStore } from 'pinia'

import { getSmartContract, useStore } from '@/store/index'
import {
  convertBigNumberToBigNumberJs,
  defaultProvider,
  ftmPublicProvider
} from '@/utils/blockchain'
import smartContracts from '@/utils/SmartContracts/smartContracts'
import SmartContracts from '@/utils/SmartContracts/smartContracts'
import { calculatePriceOfPair, getTokenInfo } from '@/utils/token/tokenCache'

export interface PriceState {
  combFtmPrice: BigNumberJs
  ftmUsdcPrice: BigNumberJs
}

// NOTE: these variables are placed intentionally outside pinia store management, as these objects don't like proxies and don't work properly
const spiritSwapFactoryContract = new ethers.Contract(
  smartContracts.uniswap_factory.spirit,
  smartContracts.uniswap_factory.ABI,
  ftmPublicProvider
)

let combFtmPairContract: ethers.Contract

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const usePriceStore = defineStore('price', {
  state(): PriceState {
    return {
      combFtmPrice: new BigNumberJs(0),
      ftmUsdcPrice: new BigNumberJs(0)
    }
  },
  getters: {
    combUsdcPrice(): BigNumberJs {
      return this.combFtmPrice.multipliedBy(this.ftmUsdcPrice)
    }
  },
  actions: {
    async initPairContracts() {
      combFtmPairContract = new ethers.Contract(
        await spiritSwapFactoryContract.getPair(
          smartContracts.erc20.FTM,
          smartContracts.COMB.address
        ),
        smartContracts.tokenPair.ABI,
        ftmPublicProvider
      )
    },
    async fetchCombFtmPrice() {
      const store = useStore()
      store.loadingCounter++
      store.isLoading.combFtmPrice = true

      try {
        this.combFtmPrice = await this.getPriceOfToken(
          smartContracts.COMB.address,
          combFtmPairContract
        )
        store.loadingCounter--
        store.isLoading.combFtmPrice = false
      } catch (e) {
        setTimeout(() => {
          store.loadingCounter--
          store.isLoading.combFtmPrice = false
          this.fetchCombFtmPrice()
        }, 3000)
      }
    },

    async fetchFtmUsdcPrice() {
      const store = useStore()
      store.loadingCounter++
      store.isLoading.ftmUsdcPrice = true

      try {
        const ORACLE_PRECISION = 8

        this.ftmUsdcPrice = convertBigNumberToBigNumberJs(
          await getSmartContract(
            SmartContracts.Oracle,
            defaultProvider
          ).latestAnswer(),
          ORACLE_PRECISION // 8 decimal precision
        )

        store.loadingCounter--
        store.isLoading.ftmUsdcPrice = false
      } catch (e) {
        setTimeout(() => {
          store.loadingCounter--
          store.isLoading.ftmUsdcPrice = false
          this.fetchCombFtmPrice()
        }, 3000)
      }
    },
    async getPriceOfToken(token: string, pairContract: Contract) {
      const [reserve0, reserve1]: ethers.BigNumber[] =
        await pairContract.getReserves()
      const tokenInfo0 = await getTokenInfo(await pairContract.token0())
      const tokenInfo1 = await getTokenInfo(await pairContract.token1())

      if (!tokenInfo0 || !tokenInfo1) {
        console.error('Error trying to getPriceOfToken', token)
        return new BigNumberJs(0)
      }
      return calculatePriceOfPair(
        { tokenInfo: tokenInfo0, amount: reserve0 },
        { tokenInfo: tokenInfo1, amount: reserve1 },
        token
      )
    }
  }
})
