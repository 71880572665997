import { useStore } from '@/store'

export function getReportedMessage(err) {
  let message
  if (err.data) {
    message = err.data.message
  } else if (err.error) {
    message = err.error.message
  } else {
    message = err.message
  }

  return message
}

export function handleRPCError(
  err,
  updateCountersCallback,
  retryCallback,
  retryTimeout = 3000
) {
  if (err.data && err.data.message && err.data.message.includes('No nodes')) {
    updateCountersCallback()
    return
  }

  if (retryCallback) {
    setTimeout(() => {
      updateCountersCallback()
      retryCallback(updateCountersCallback)
    }, retryTimeout)
  }
}

/** */
export const handleRPCErrorWithLoading = (
  err: any,
  retryCallback: () => void,
  retryTimeout = 3000
): void =>
  handleRPCError(
    err,
    () => useStore().loadingCounter--,
    retryCallback,
    retryTimeout
  )

/**
 * If a RPC endpoint doesn't sync up fast enough, we need to make sure that we refetch data just in case.
 */
export function handleLateSyncWithLoading<T>(
  valueShouldChange: boolean,
  oldValue: T,
  newValue: T,
  retryCallback: () => void,
  duration = 3000
): void {
  useStore().loadingCounter--
  if (valueShouldChange && oldValue === newValue) {
    setTimeout(() => retryCallback, duration)
  }
}

/**
 * Try catches a callback and reports a message if an error is thrown.
 * @param callback action called
 */
export async function handleAsyncCallbackWithReportedMessage(
  callback: () => Promise<void>
): Promise<void> {
  try {
    await callback()
  } catch (err) {
    getReportedMessage(err)
  }
}
