<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 32 32"
    xml:space="preserve"
    stroke="currentColor"
    stroke-width="0.75"
  >
    <path
      id="movement--of--goods--01_1_"
      d="M9.64,6c0,0.199,0.161,0.36,0.36,0.36h4c0.199,0,0.36-0.161,0.36-0.36V4.36H22V3.64h-7.64
	V2c0-0.199-0.161-0.36-0.36-0.36h-4C9.801,1.64,9.64,1.801,9.64,2v1.64H2v0.72h7.64C9.64,4.36,9.64,6,9.64,6z M10.36,2.36h3.28v3.28
	h-3.28V2.36z M14.36,26c0-0.199-0.161-0.36-0.36-0.36h-4c-0.199,0-0.36,0.161-0.36,0.36v1.64H2v0.721h7.64V30
	c0,0.199,0.161,0.36,0.36,0.36h4c0.199,0,0.36-0.161,0.36-0.36v-1.64H22v-0.72h-7.64C14.36,27.64,14.36,26,14.36,26z M13.64,29.64
	h-3.28v-3.28h3.28V29.64z M20.64,8v1.64H5v0.72h15.64V12c0,0.199,0.161,0.36,0.36,0.36h4c0.199,0,0.36-0.161,0.36-0.36V8
	c0-0.199-0.161-0.36-0.36-0.36h-4C20.801,7.64,20.64,7.801,20.64,8z M21.36,8.36h3.279v3.28H21.36V8.36z M25,19.64h-4
	c-0.199,0-0.36,0.161-0.36,0.36v1.64H5v0.721h15.64V24c0,0.199,0.161,0.36,0.36,0.36h4c0.199,0,0.36-0.161,0.36-0.36v-4
	C25.36,19.801,25.199,19.64,25,19.64z M24.64,23.64h-3.28v-3.28h3.279L24.64,23.64L24.64,23.64z M28.255,12.746l-0.51,0.509
	l2.386,2.386H18.36V14c0-0.199-0.161-0.36-0.36-0.36h-4c-0.199,0-0.36,0.161-0.36,0.36v1.64H1v0.72h12.64V18
	c0,0.199,0.161,0.36,0.36,0.36h4c0.199,0,0.36-0.161,0.36-0.36v-1.64h11.771l-2.386,2.385l0.51,0.51L31.509,16L28.255,12.746z
	 M17.64,17.64h-3.28v-3.28h3.28V17.64z"
    />
  </svg>
</template>
