import { usePriceStore } from '@/store/price'

const tokenAddressBook = {
  '0xae45a827625116d6c0c40b5d7359ecf68f8e9afd': 'comb-finance',
  '0x289f1d235ec2d2a7934234284299284578af0abc': 'comb-finance', // Mock token
  '0x04068da6c83afcfa0e13ba15a6696662335d5b75': 'usd-coin',
  '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83': 'fantom',
  '0x321162cd933e2be498cd2267a90534a804051b11': 'bitcoin',
  '0x74b23882a30290451a17c44f4f05243b6b58c76d': 'ethereum',
  '0x049d68029688eabf473097a2fc38ef61633a3c7a': 'tether',
  '0x82f0b8b456c1a451378467398982d4834b6829c1': 'magic-internet-money',
  '0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9': 'liquiddriver',
  '0xfb98b335551a418cd0737375a2ea0ded62ea213b': 'mimatic',
  '0x841fad6eae12c286d1fd18d1d525dffa75c7effe': 'spookyswap',
  '0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37': 'tomb-shares',
  '0xf24bcf4d1e507740041c9cfd2dddb29585adce1e': 'beethoven-x',
  '0x5cc61a78f164885776aa610fb0fe1257df78e59b': 'spiritswap',
  '0xe0654c8e6fd4d733349ac7e09f6f23da256bf475': 'scream',
  '0xd31fcd1f7ba190dbc75354046f6024a9b86014d7': 'solidex',
  '0x888ef71766ca594ded1f0fa3ae64ed2941740a20': 'solidly',
  '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e': 'dai'
}
const stablesIdentifierBook = [
  'dai',
  'tether',
  'magic-internet-money',
  'usd-coin'
]

/**
 * Retrieve the identifier for CoinGecko according to the address.
 *
 * @param address of the token
 * @returns the identifier used for CoinGecko
 */
export const retrieveIdFromAddressBook = (address: string): string =>
  tokenAddressBook[address.toLowerCase()]

/**
 * Retrieve the address for tokenAddressBook according to the identifier.
 *
 * @param identifier of the token used for CoinGecko
 * @returns the token contract address
 */
export const retrieveAddressByIdentifier = (identifier: string): string => {
  const tokenAddress = Object.entries(tokenAddressBook).filter(
    (token) => token[1] === identifier
  )[0]

  return tokenAddress.length > 0 ? tokenAddress[0] : ''
}

/**
 * Fetch the price of a token according to its address or identifier
 *
 * @param address of the token
 * @param retrieveFromAddressBook whether the address should do a look up for an identifier
 * @returns the response of the fetched oracle endpoint
 */
export async function fetchOracleEndpoint(
  address: string,
  retrieveFromAddressBook = true
): Promise<Response> {
  const injectedIdentifier = retrieveFromAddressBook
    ? retrieveIdFromAddressBook(address.toLowerCase())
    : address

  const url = `https://api.coingecko.com/api/v3/simple/price?ids=${injectedIdentifier}&vs_currencies=usd`
  // Let the user manually pass in a retrieved identifier
  return await fetch(url)
}

/**
 * Fetch the price of the token according to its address or identifier. Will automatically extract from json.
 *
 * @param address or identifier of the token
 * @param retrieveFromAddressBook whether the address should do a look up for an identifier
 * @returns the response of the fetched oracle endpoint
 */
export async function fetchPriceFromOracleEndpoint(
  address: string,
  retrieveFromAddressBook = true
): Promise<number> {
  const identifier = retrieveFromAddressBook
    ? retrieveIdFromAddressBook(address.toLowerCase())
    : address

  if (stablesIdentifierBook.includes(identifier)) {
    return 1
  }

  if (identifier === 'fantom') {
    const priceStore = usePriceStore()
    if (priceStore.ftmUsdcPrice.eq(0)) {
      await priceStore.initPairContracts()
      await priceStore.fetchFtmUsdcPrice()
    }
    return priceStore.ftmUsdcPrice.toNumber()
  }

  // Fetch the json from the endpoint and extract it using the identifier.
  return (await (await fetchOracleEndpoint(identifier, false)).json())[
    identifier
  ].usd
}

/**
 * Pull trading APRs from Beefy using their API for pools
 *
 * @param identifier beefy api identifier
 * @returns trading apr
 */
export async function fetchBeefyTradingApr(
  identifier: string
): Promise<number> {
  const response = await fetch(`https://api.beefy.finance/apy/breakdown`)
  const json = await response.json()
  return json[identifier].tradingApr
}
