import BigNumberJs from 'bignumber.js'
import { ethers } from 'ethers'

import { createToast } from '@/plugins/toastNotificationsPlugin'
import { useWalletStore } from '@/store/wallet'
import SmartContracts from '@/utils/SmartContracts/smartContracts'

import { AddEthereumChainParameter } from '../../../types'
import { isOnMainnet } from '../environment'
import { getReportedMessage } from '../errorHandling'
import { getTokenSmartContract } from '../token/tokenCache'

const DEFAULT_PROVIDER_RPC = isOnMainnet
  ? 'https://fantom.publicnode.com'
  : 'https://rpc.testnet.fantom.network/'

export const TWO_DIGIT_PRECISION = 2

export const MORALIS_FTM = '0xfa'

export const addMainnetParams: AddEthereumChainParameter = {
  chainId: '250', // A 0x-prefixed hexadecimal string
  chainName: 'Fantom Mainnet',
  nativeCurrency: {
    name: 'FTM',
    symbol: 'FTM', // 2-6 characters long
    decimals: 18
  },
  rpcUrls: ['https://fantom.publicnode.com'],
  blockExplorerUrls: ['https://ftmscan.com/']
}

export const addTestnetParams: AddEthereumChainParameter = {
  chainId: '0xfa2', // A 0x-prefixed hexadecimal string
  chainName: 'Fantom testnet',
  nativeCurrency: {
    name: 'FTM',
    symbol: 'FTM', // 2-6 characters long
    decimals: 18
  },
  rpcUrls: ['https://rpc.testnet.fantom.network/'],
  blockExplorerUrls: ['https://testnet.ftmscan.com/']
}

export const defaultProvider: ethers.providers.JsonRpcProvider =
  new ethers.providers.JsonRpcProvider(DEFAULT_PROVIDER_RPC)

export const ftmPublicProvider = new ethers.providers.JsonRpcProvider(
  process.env.VUE_APP_PUBLIC_RPC
)

export const nodeRewardManagementContractPublic = new ethers.Contract(
  SmartContracts.NodeRewardManagement.address,
  SmartContracts.NodeRewardManagement.ABI,
  ftmPublicProvider
)

export const fantomChainId = parseInt(process.env.VUE_APP_CHAIN_ID!)

export const convertBigNumberToBigNumberJs = (
  value: ethers.BigNumber,
  decimals = 18
) => {
  return new BigNumberJs(ethers.utils.formatUnits(value, decimals))
}
export const convertBigNumberJsToBigNumber = (
  value: Omit<BigNumberJs, '_isBigNumber'>,
  decimals = 18
) => {
  return ethers.utils.parseUnits(value.toFixed(), decimals)
}

export const formatNumber = (val = 0, decimals = 2): string =>
  val.toLocaleString([], { maximumFractionDigits: decimals })

export const formatBigNumber = (
  val: ethers.BigNumber,
  decimal = 18,
  precision: number | null = null
) => {
  return formatBigNumberJs(
    convertBigNumberToBigNumberJs(val, decimal),
    precision
  )
}

export const formatBigNumberJs = (
  number: Omit<BigNumberJs, '_isBigNumber'>,
  precision: number | null = null
) => {
  if (!precision) {
    precision = number.gte(1) ? 2 : 4
  }
  return number.toFormat(precision, BigNumberJs.ROUND_FLOOR)
}

export const convertBigNumberToNumber = (
  value: ethers.BigNumber,
  units = 18
): number => new BigNumberJs(ethers.utils.formatUnits(value, units)).toNumber()

export const fetchTokenBalance = async (
  tokenAddress: string,
  oldValue?: ethers.BigNumber,
  timesTried = 0
): Promise<ethers.BigNumber> => {
  const wallet = useWalletStore()
  if (timesTried >= 5 || !wallet.address) {
    return ethers.BigNumber.from('0')
  }

  try {
    const tokenContract = getTokenSmartContract(tokenAddress)

    const balance = await tokenContract.balanceOf(wallet.address)

    if (oldValue && oldValue.eq(balance)) {
      await sleep(3000)
      return await fetchTokenBalance(tokenAddress, oldValue, timesTried++)
    } else {
      return balance
    }
  } catch (err) {
    await sleep(3000)
    return await fetchTokenBalance(tokenAddress, oldValue, timesTried++)
  }
}

/**
 * Fetch data from chain and verify, if data was changed.
 * You have to provide hasValueChanged callback which checks if new value is different to the old one.
 * If value is the same, fetching repeats after 3 seconds.
 *
 * @param fetchCallback
 * @param hasValueChanged
 * @param timesTried
 */
export const fetchDataFromChain = <T>(
  fetchCallback: () => Promise<T>,
  hasValueChanged?: (newValue: T) => boolean,
  timesTried = 0
): Promise<T> => {
  return new Promise(async (resolve, reject) => {
    if (timesTried >= 3) {
      return reject(new Error("Value didn't change"))
    }

    try {
      const value = await fetchCallback()

      if (hasValueChanged && !hasValueChanged(value)) {
        setTimeout(() => {
          resolve(
            fetchDataFromChain(fetchCallback, hasValueChanged, timesTried + 1)
          )
        }, 3000)
      } else {
        resolve(value)
      }
    } catch (err) {
      return reject(err)
    }
  })
}

export const checkIfTokenApproved = (
  tokenAddress: string,
  spender: string,
  oldValue?: boolean
) => {
  return fetchDataFromChain<boolean>(
    async () => {
      const wallet = useWalletStore()
      if (!wallet.address) {
        return false
      }

      const tokenContract = getTokenSmartContract(tokenAddress)
      if (!tokenContract) {
        return false
      }

      const allowance = await tokenContract.allowance(wallet.address, spender)
      const balance = await tokenContract.balanceOf(wallet.address)

      return !allowance.isZero() && allowance.gte(balance)
    },
    oldValue ? (newValue) => oldValue !== newValue : undefined
  )
}

export async function makeCallWithPendingToast(props: {
  callTx: () => Promise<ethers.providers.TransactionResponse | undefined>
  onBeforeSend?: () => void
  onWaiting?: () => void
  onSuccess?: () => void
  onError?: (err) => void
  onFinally?: () => void
  waitingToastText?: string
  successToastText?: string
}): Promise<void> {
  let waitingToast
  props.onBeforeSend?.call({})
  try {
    const tx = await props.callTx()

    if (!tx) return

    if (props.waitingToastText) {
      waitingToast = createToast({
        type: 'info',
        text: props.waitingToastText,
        duration: 0
      })
    }

    props.onWaiting?.call({})

    await tx.wait()

    if (props.successToastText) {
      createToast({
        type: 'success',
        text: props.successToastText
      })
    }

    if (props.onSuccess) {
      props.onSuccess()
    }
  } catch (err: any) {
    if (props.onError) {
      props.onError(err)
    }

    createToast({
      type: 'error',
      text: getReportedMessage(err)
    })
  } finally {
    if (waitingToast) {
      waitingToast.close()
    }
    props.onFinally?.call({})
  }
}

const sleep = (ms) => new Promise((r) => setTimeout(r, ms))
