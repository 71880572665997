import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { isOnTestnet } from '@/utils/environment'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/pools'
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/charts',
    name: 'charts',
    component: () =>
      import(/* webpackChunkName: "charts" */ '../views/Charts.vue')
  },
  {
    path: '/vaults',
    name: 'vaults',
    component: () =>
      import(/* webpackChunkName: "vaults" */ '../views/Vaults.vue')
  },
  {
    path: '/zcomb',
    name: 'zcomb',
    component: () =>
      import(/* webpackChunkName: "zcomb" */ '../views/zComb.vue')
  },
  {
    path: '/pools',
    name: 'pools',
    component: () =>
      import(/* webpackChunkName: "pools" */ '../views/Pools.vue')
  },
  {
    path: '/yield-optimizer',
    name: 'yield',
    component: () =>
      import(
        /* webpackChunkName: "yield-optimizer" */ '../views/YieldOptimizer.vue'
      )
  },
  {
    path: '/bear',
    name: 'bear',
    component: () =>
      import(/* webpackChunkName: "bear-beets" */ '../views/BearBeets.vue')
  },
  {
    path: '/mint',
    name: 'mint',
    component: () =>
      import(/* webpackChunkName: "yield-optimizer" */ '../views/MintPage.vue')
  },
  {
    path: '/marketplace',
    name: 'marketplace',
    component: () =>
      import(/* webpackChunkName: "marketplace" */ '../views/Marketplace.vue')
  },
  {
    path: '/listing',
    name: 'listing',
    component: () =>
      import(
        /* webpackChunkName: "marketplace-listing" */ '../views/MarketplaceCreateListing.vue'
      )
  },
  {
    path: '/my-listings',
    name: 'my-listings',
    component: () =>
      import(
        /* webpackChunkName: "marketplace-listing" */ '../views/MarketplaceAccountListings.vue'
      )
  },
  {
    path: '/cert-pdf',
    name: 'cert-pdf',
    component: () =>
      import(/* webpackChunkName: "cert-pdf" */ '../views/CertPdf.vue')
  },
  {
    path: '/admin-dashboard',
    name: 'admin',
    component: () =>
      import(
        /* webpackChunkName: "admin-dashboard" */ '../views/AdminDashboard.vue'
      ),
    beforeEnter: () => {
      return isOnTestnet
        ? true
        : {
            path: '/dashboard'
          }
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
