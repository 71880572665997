import BigNumberJs from 'bignumber.js'
import { ethers } from 'ethers'
import { Duration } from 'luxon'
import { defineStore } from 'pinia'

import {
  createToast,
  ToastNotification
} from '@/plugins/toastNotificationsPlugin'
import { provider, signer, useWalletStore } from '@/store/wallet'
import {
  convertBigNumberToBigNumberJs,
  defaultProvider,
  fantomChainId,
  fetchDataFromChain,
  fetchTokenBalance,
  nodeRewardManagementContractPublic
} from '@/utils/blockchain'
import { getReportedMessage, handleRPCError } from '@/utils/errorHandling'
import hiveTier from '@/utils/misc/hiveTier'
import SmartContracts from '@/utils/SmartContracts/smartContracts'
import { getTokenSmartContract } from '@/utils/token/tokenCache'

import { ToastStatus } from '../../types'

declare global {
  interface Window {
    ethereum: any
  }
}

export interface Hive {
  name: string
  multiplier: number
  creationTime: number
  metadata: string
}

export interface StoreState {
  loadingCounter: number
  isLoading: {
    nodesAmount: boolean
    claimableRewards: boolean
    combBalance: boolean
    nectarBalance: boolean
    nodes: boolean
    combFtmPrice: boolean
    ftmUsdcPrice: boolean
    tshareFtmPrice: boolean
    _2shareFtmPrice: boolean
    _2ombFtmPrice: boolean
    tombFtmPrice: boolean
    combRewardPerTick: boolean
  }
  nodesAmount: number
  combBalance: ethers.BigNumber
  nectarBalance: ethers.BigNumber
  claimableRewardsAmount: ethers.BigNumber
  claimableTaxFee: ethers.BigNumber
  isApprovedToSpendComb: undefined | boolean
  isApprovedToSpendCombForNect: undefined | boolean
  isApprovedForVeComb: boolean | undefined
  isApprovedToSpendCombForPodsManager: boolean | undefined
  durationTillNextDistribution: undefined | Duration
  hives: Hive[]
  selectedHive: Hive | null
  hivesAmount: {
    brough: number
    topBar: number
    langstroth: number
    warre: number
  }
  combRewardPerTick: BigNumberJs
  regressiveTax: {
    min: BigNumberJs
    high: BigNumberJs
    minTax: number
    highTax: number
  }
  endpointRegionSuffix: string
  isNodeCapReached: boolean | undefined
}

// NOTE: these variables are placed intentionally outside pinia store management, as these objects don't like proxies and don't work properly
export let nodeRewardManagementContract: ethers.Contract | undefined
export let hiveBeaconContract: ethers.Contract | undefined
export let compounderContract: ethers.Contract | undefined
export let nectarContract: ethers.Contract | undefined
export let veCOMBContract: ethers.Contract | undefined
export let combContract: ethers.Contract | undefined
export let feeDistributorContract: ethers.Contract | undefined

let tillNextDistributionInterval
let shouldRefetchClaimableAfterNextDistribution = false

enum IsApprovedContract {
  APPROVED_FOR_NECT = 'isApprovedToSpendCombForNect',
  APPROVED_FOR_veCOMB = 'isApprovedForVeComb',
  APPROVED_FOR_COMB = 'isApprovedToSpendComb',
  APPROVED_FOR_PODS_MANAGER = 'isApprovedToSpendCombForPodsManager'
}

export const getSmartContract = (
  smartContract,
  provider?: ethers.Signer | ethers.providers.JsonRpcProvider,
  abi?: ethers.ContractInterface
): ethers.Contract =>
  new ethers.Contract(
    smartContract.address,
    abi ?? smartContract.ABI ?? smartContract.abi,
    provider ?? defaultProvider
  )

const getApprovedContract = (spenderAddress: string): string => {
  let approvedContract: string
  switch (spenderAddress) {
    case SmartContracts.NECTAR.address:
      approvedContract = IsApprovedContract.APPROVED_FOR_NECT
      break
    case SmartContracts.veCOMB.address:
      approvedContract = IsApprovedContract.APPROVED_FOR_veCOMB
      break
    case SmartContracts.PodsManager.address:
      approvedContract = IsApprovedContract.APPROVED_FOR_PODS_MANAGER
      break
    default:
      approvedContract = IsApprovedContract.APPROVED_FOR_COMB
  }
  return approvedContract
}

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useStore = defineStore('main', {
  state(): StoreState {
    return {
      loadingCounter: 0,
      isLoading: {
        nodesAmount: false,
        claimableRewards: false,
        combBalance: false,
        nectarBalance: false,
        nodes: false,
        combFtmPrice: false,
        ftmUsdcPrice: false,
        tshareFtmPrice: false,
        _2shareFtmPrice: false,
        tombFtmPrice: false,
        _2ombFtmPrice: false,
        combRewardPerTick: false
      },
      nodesAmount: 0,
      combBalance: ethers.BigNumber.from(0),
      nectarBalance: ethers.BigNumber.from(0),
      claimableRewardsAmount: ethers.BigNumber.from(0),
      claimableTaxFee: ethers.BigNumber.from(0),
      isApprovedToSpendComb: undefined,
      isApprovedToSpendCombForNect: undefined,
      isApprovedForVeComb: undefined,
      isApprovedToSpendCombForPodsManager: undefined,
      durationTillNextDistribution: undefined,
      hives: [],
      selectedHive: null,
      hivesAmount: {
        brough: 0,
        topBar: 0,
        langstroth: 0,
        warre: 0
      },
      combRewardPerTick: new BigNumberJs(0),
      regressiveTax: {
        min: new BigNumberJs(0),
        high: new BigNumberJs(0),
        minTax: 0,
        highTax: 0
      },
      endpointRegionSuffix: '',
      isNodeCapReached: undefined
    }
  },
  getters: {
    wallet() {
      return useWalletStore()
    },
    sumEarningMultiplier(): number {
      let multiplier = 0
      this.hives.forEach((hive) => {
        multiplier += hive.multiplier
      })
      return multiplier
    },
    myCombEarningPerTick(): BigNumberJs {
      return this.combRewardPerTick
        .multipliedBy(this.sumEarningMultiplier)
        .dividedBy(100)
    },
    dailyCombEarning(): BigNumberJs {
      return this.myCombEarningPerTick.multipliedBy(6)
    },
    nextTax(): number {
      if (this.claimableTaxFee.eq(this.regressiveTax.minTax)) {
        return this.regressiveTax.minTax / 2
      }
      return this.regressiveTax.highTax
    },
    fullTicksTillNextTaxTier(): number {
      if (
        this.sumEarningMultiplier === 0 ||
        this.myCombEarningPerTick.eq(0) ||
        this.regressiveTax.min.eq(0) ||
        this.regressiveTax.high.eq(0)
      ) {
        return -1
      }

      const adjustedMin = this.regressiveTax.min
        .multipliedBy(this.sumEarningMultiplier)
        .div(100)
      const adjustedHigh = this.regressiveTax.high
        .multipliedBy(this.sumEarningMultiplier)
        .div(100)
      const amount = convertBigNumberToBigNumberJs(this.claimableRewardsAmount)

      if (amount.lt(adjustedMin)) {
        // tier 1 tax - currently 38%
        const amountDifference = adjustedMin.minus(amount)
        return (
          Math.ceil(
            amountDifference.div(this.myCombEarningPerTick).toNumber()
          ) - 1
        )
      } else if (amount.gt(adjustedHigh)) {
        // tier 3 tax - currently 5%
        return 0
      } else {
        // tier 2 tax - currently 19% => half of 38%
        const amountDifference = adjustedHigh.minus(amount)
        return (
          Math.ceil(
            amountDifference.div(this.myCombEarningPerTick).toNumber()
          ) - 1
        )
      }
    }
  },
  actions: {
    async init() {
      await this.initSmartContracts()
      this.fetchCombBalance()
      this.fetchNectarBalance()
      this.checkIfApprovedToSpendComb(SmartContracts.HiveBeacon.address)
      this.checkIfApprovedToSpendComb(SmartContracts.NECTAR.address)
      this.checkIfApprovedToSpendComb(SmartContracts.veCOMB.address)
      this.checkIfApprovedToSpendComb(SmartContracts.PodsManager.address)
    },
    async reset() {
      await this.resetSmartContracts()
      this.resetCombBalance()
      this.resetNectarBalance()
    },
    async initSmartContracts(): Promise<void> {
      if (
        !provider ||
        (provider.network && provider.network.chainId !== fantomChainId)
      ) {
        return
      }
      return new Promise((res) => {
        if (
          !provider ||
          !provider.network ||
          provider.network.chainId !== fantomChainId
        ) {
          setTimeout(async () => {
            await this.initSmartContracts()
            res()
          }, 350)
          return
        }
        if (
          nodeRewardManagementContract &&
          hiveBeaconContract &&
          compounderContract &&
          nectarContract &&
          veCOMBContract &&
          feeDistributorContract
        ) {
          res()
          return
        }

        nodeRewardManagementContract = getSmartContract(
          SmartContracts.NodeRewardManagement,
          signer
        )
        hiveBeaconContract = getSmartContract(SmartContracts.HiveBeacon, signer)
        compounderContract = getSmartContract(
          SmartContracts.NectarCompounder,
          signer
        )
        nectarContract = getSmartContract(SmartContracts.NECTAR, signer)
        veCOMBContract = getSmartContract(SmartContracts.veCOMB, signer)
        feeDistributorContract = getSmartContract(
          SmartContracts.FeeDistributor,
          signer
        )
        combContract = new ethers.Contract(
          SmartContracts.COMB.address,
          SmartContracts.erc20.ABI,
          signer
        )
        res()
      })
    },
    resetSmartContracts() {
      nodeRewardManagementContract = undefined
      hiveBeaconContract = undefined
      compounderContract = undefined
      nectarContract = undefined
      veCOMBContract = undefined
      feeDistributorContract = undefined
    },
    fetchNodesData() {
      this.fetchNodesAmount()
      this.fetchClaimableRewards()
      this.fetchNodes()
    },
    resetNodesData() {
      this.nodesAmount = 0

      this.claimableRewardsAmount = ethers.BigNumber.from(0)
      this.claimableTaxFee = ethers.BigNumber.from(0)

      this.resetNodes()
    },
    async fetchNodesAmount(valueShouldChange = false) {
      if (!this.wallet.address || !nodeRewardManagementContract) {
        return
      }

      this.loadingCounter++
      this.isLoading.nodesAmount = true
      const oldAmount = this.nodesAmount
      try {
        this.nodesAmount = await nodeRewardManagementContract.getNodeNumberOf(
          this.wallet.address
        )
        if (valueShouldChange && oldAmount === this.nodesAmount) {
          setTimeout(() => {
            this.fetchNodesAmount(true)
          }, 3000)
        }
        this.loadingCounter--
        this.isLoading.nodesAmount = false
      } catch (err) {
        handleRPCError(
          err,
          () => {
            this.loadingCounter--
            this.isLoading.nodesAmount = false
          },
          () => {
            this.fetchNodesAmount(valueShouldChange)
          }
        )
      }
    },
    async fetchClaimableRewards(valueShouldChange = false) {
      if (!this.wallet.address || !nodeRewardManagementContract) {
        return
      }

      this.loadingCounter++
      this.isLoading.claimableRewards = true
      const oldClaimable = this.claimableRewardsAmount
      try {
        this.claimableRewardsAmount =
          await nodeRewardManagementContract.getRewardAmountOf(
            this.wallet.address
          )
        this.claimableTaxFee =
          await nodeRewardManagementContract.getNodesRewardsTax(
            this.wallet.address,
            this.claimableRewardsAmount
          )

        // if new amount is same as old one, try to refetch later
        if (valueShouldChange && this.claimableRewardsAmount.eq(oldClaimable)) {
          setTimeout(() => {
            this.fetchClaimableRewards(true)
          }, 3000)
        }
        this.loadingCounter--
        this.isLoading.claimableRewards = false
      } catch (err) {
        handleRPCError(
          err,
          () => {
            this.loadingCounter--
            this.isLoading.claimableRewards = false
          },
          () => {
            this.fetchClaimableRewards(valueShouldChange)
          }
        )
      }
    },
    async fetchCombBalance(valueShouldChange = false) {
      try {
        this.combBalance = await fetchTokenBalance(
          SmartContracts.COMB.address,
          valueShouldChange ? this.combBalance : undefined
        )
      } catch (err) {
        this.resetCombBalance()
      }
    },
    resetCombBalance() {
      this.combBalance = ethers.BigNumber.from(0)
    },
    async fetchNectarBalance(valueShouldChange = false) {
      try {
        this.nectarBalance = await fetchTokenBalance(
          SmartContracts.NECTAR.address,
          valueShouldChange ? this.nectarBalance : undefined
        )
      } catch (err) {
        this.resetNectarBalance()
      }
    },
    resetNectarBalance() {
      this.nectarBalance = ethers.BigNumber.from(0)
    },
    async fetchNodes(valueShouldChange = false) {
      if (!nodeRewardManagementContract || !this.wallet.address) {
        return
      }

      this.loadingCounter++
      this.isLoading.nodes = true
      const oldLength = this.hives.length
      try {
        const names = (
          await nodeRewardManagementContract.getNodeNames(this.wallet.address)
        ).split('#')

        const creationTime = (
          await nodeRewardManagementContract.getNodesCreationTime(
            this.wallet.address
          )
        ).split('#')

        const multipliers = (
          await nodeRewardManagementContract.getNodesMultipliers(
            this.wallet.address
          )
        ).split('#')

        const metadata = (
          await nodeRewardManagementContract.getNodesMetadata(
            this.wallet.address
          )
        ).split('#')

        this.resetNodes()
        for (let i = 0; i < creationTime.length; i++) {
          const hive = {
            name: names[i],
            creationTime: parseInt(creationTime[i]),
            multiplier: parseInt(multipliers[i]),
            metadata: metadata[i]
          }
          this.hives.push(hive)
          if (hive.multiplier / 100 === hiveTier.brough.multiplier) {
            this.hivesAmount.brough++
          } else if (hive.multiplier >= 100 && hive.multiplier <= 115) {
            this.hivesAmount.topBar++
          } else if (hive.multiplier / 100 === hiveTier.langstroth.multiplier) {
            this.hivesAmount.langstroth++
          } else if (hive.multiplier / 100 === hiveTier.warre.multiplier) {
            this.hivesAmount.warre++
          }
        }

        if (valueShouldChange && oldLength === this.hives.length) {
          setTimeout(() => {
            this.fetchNodes(true)
          }, 3000)
        }
        this.loadingCounter--
        this.isLoading.nodes = false
      } catch (err) {
        handleRPCError(
          err,
          () => {
            this.loadingCounter--
            this.isLoading.nodes = false
          },
          () => {
            this.fetchNodes(valueShouldChange)
          }
        )
      }
    },
    resetNodes() {
      this.hives = []
      this.hivesAmount.brough = 0
      this.hivesAmount.topBar = 0
      this.hivesAmount.langstroth = 0
      this.hivesAmount.warre = 0
    },
    async checkIfApprovedToSpendComb(
      spenderAddress,
      valueShouldChange = false
    ) {
      const approvedPropertyName = getApprovedContract(spenderAddress)

      this.loadingCounter++
      try {
        this[approvedPropertyName] = await fetchDataFromChain<boolean>(
          async () => {
            if (!this.wallet.address) {
              return false
            }

            const tokenContract = getTokenSmartContract(
              SmartContracts.COMB.address
            )
            if (!tokenContract) {
              return false
            }

            const allowance: ethers.BigNumber = await tokenContract.allowance(
              this.wallet.address,
              spenderAddress
            )
            const needToBeApproved = this.combBalance.add(
              this.claimableRewardsAmount
            )

            return !allowance.isZero() && allowance.gte(needToBeApproved)
          },
          valueShouldChange
            ? (newValue) => this[approvedPropertyName] !== newValue
            : undefined
        )

        this.loadingCounter--
      } catch (err) {
        createToast({
          type: ToastStatus.Error,
          text: getReportedMessage(err)
        })
        this.loadingCounter--
        this[approvedPropertyName] = false
      }
    },
    async requestApproveToSpendComb(spenderAddress: string) {
      if (!this.wallet.address || !signer) {
        return
      }

      let waitingToast: ToastNotification | undefined

      try {
        const combErc20SmartContract = (
          await getTokenSmartContract(SmartContracts.COMB.address)
        ).connect(signer)

        const txResponse = await combErc20SmartContract.approve(
          spenderAddress,
          ethers.BigNumber.from(
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
          )
        )

        waitingToast = createToast({
          type: ToastStatus.Info,
          text: 'Approve transaction was submitted. Awaiting confirmations...',
          duration: 0
        })

        await txResponse.wait()

        waitingToast.close()

        this.checkIfApprovedToSpendComb(spenderAddress, true)

        createToast({
          type: ToastStatus.Success,
          text: `Successfully approved.`
        })
      } catch (err) {
        if (waitingToast) {
          waitingToast.close()
        }
        createToast({
          type: ToastStatus.Error,
          text: getReportedMessage(err)
        })
      }
    },
    async fetchNextDistributionTime() {
      if (tillNextDistributionInterval) {
        clearInterval(tillNextDistributionInterval)
      }

      try {
        const nextTimestamp = (
          await nodeRewardManagementContractPublic.nextDistribution()
        ).toNumber()
        const durationTillNextDistribution = Duration.fromObject({
          seconds: nextTimestamp - Math.ceil(new Date().getTime() / 1000)
        })

        if (durationTillNextDistribution.toMillis() < 3000) {
          this.durationTillNextDistribution = Duration.fromMillis(0)

          shouldRefetchClaimableAfterNextDistribution = true
          setTimeout(() => {
            this.fetchNextDistributionTime()
          }, 10000)
        } else {
          if (shouldRefetchClaimableAfterNextDistribution) {
            this.fetchClaimableRewards(true)
            shouldRefetchClaimableAfterNextDistribution = false
          }
          this.durationTillNextDistribution = durationTillNextDistribution

          tillNextDistributionInterval = setInterval(() => {
            this.durationTillNextDistribution =
              this.durationTillNextDistribution!.minus(1000)
            if (this.durationTillNextDistribution.toMillis() < 3000) {
              this.fetchNextDistributionTime()
            }
          }, 1000)
        }
      } catch (e) {
        setTimeout(() => {
          this.fetchNextDistributionTime()
        }, 10000)
      }
    },
    async fetchRegressiveTax() {
      try {
        const response =
          await nodeRewardManagementContractPublic.regressiveTax()

        this.regressiveTax.min = convertBigNumberToBigNumberJs(response[0])
        this.regressiveTax.high = convertBigNumberToBigNumberJs(response[1])
        this.regressiveTax.minTax = response[2].toNumber()
        this.regressiveTax.highTax = response[3].toNumber()
      } catch (e) {
        this.regressiveTax.min = new BigNumberJs(0)
        this.regressiveTax.high = new BigNumberJs(0)
        this.regressiveTax.minTax = 0
        this.regressiveTax.highTax = 0
        this.fetchRegressiveTax()
      }
    },
    async fetchRewardPerNode() {
      this.loadingCounter++
      this.isLoading.combRewardPerTick = true
      try {
        this.combRewardPerTick = convertBigNumberToBigNumberJs(
          await nodeRewardManagementContractPublic.rewardPerNode()
        )
        this.loadingCounter--
        this.isLoading.combRewardPerTick = false
      } catch (err) {
        handleRPCError(
          err,
          () => {
            this.loadingCounter--
            this.isLoading.combRewardPerTick = false
          },
          () => {
            this.fetchRewardPerNode()
          }
        )
      }
    },
    async verifyNodeCap() {
      await fetch('https://link.comb.financial/api/hives/multipliers').then(
        (response) => response.json()
      )
      this.isNodeCapReached = true
    },
    setSelectedHive(hive: Hive) {
      this.selectedHive = hive
    }
  }
})
