import { match } from '@/utils/misc/helpers'
import SmartContracts from '@/utils/SmartContracts/smartContracts'

export enum PodType {
  FTM = 'ftm',
  BTC = 'btc',
  ETH = 'eth',
  AVAX = 'avax',
  CAPSULE = 'capsule'
}

export interface PodData {
  videoUrl: string
  imageUrl: string
  symbol: string
  label: string
  isImage?: boolean
}

export const stringToPodType = (podType: string): PodType | undefined =>
  match(podType, {
    ftm: PodType.FTM,
    btc: PodType.BTC,
    eth: PodType.ETH,
    avax: PodType.AVAX,
    capsule: PodType.CAPSULE,
    default: undefined
  })

export const numberToPodType = (podType: number): PodType | undefined =>
  match(podType, {
    0: PodType.FTM,
    1: PodType.BTC,
    2: PodType.ETH,
    3: PodType.AVAX,
    4: PodType.CAPSULE,
    default: undefined
  })

export const podTypeToNumber = (podType: PodType): number | undefined =>
  match(podType, {
    [PodType.FTM]: 0,
    [PodType.BTC]: 1,
    [PodType.ETH]: 2,
    [PodType.AVAX]: 3,
    [PodType.CAPSULE]: 4,
    default: undefined
  })

export const podTypeToFeedComb = (podType: PodType): number | undefined =>
  match(podType, {
    [PodType.FTM]: 4,
    [PodType.BTC]: 4,
    [PodType.ETH]: 4,
    [PodType.AVAX]: 3,
    [PodType.CAPSULE]: 1.5,
    default: undefined
  })

export const podTypeLiquidator = (podType: PodType): string =>
  match(podType, {
    [PodType.FTM]: SmartContracts.FtmPodLiquidator.address,
    [PodType.BTC]: SmartContracts.BtcPodLiquidator.address,
    [PodType.ETH]: SmartContracts.EthPodLiquidator.address,
    [PodType.AVAX]: SmartContracts.AvaxPodLiquidator.address,
    [PodType.CAPSULE]: SmartContracts.TeamCombPodLiquidator.address
  })

export enum PodsActionType {
  CLAIM = 'claim',
  FEED = 'feed'
}

export const PODS: { [type: string]: PodData } = {
  [PodType.FTM]: {
    videoUrl: 'https://static-web-media.s3.us-west-2.amazonaws.com/ftm-pod.mp4',
    imageUrl: '/img/tokens/FTM.svg',
    symbol: 'FTM',
    label: 'Fantom'
  },
  [PodType.BTC]: {
    videoUrl: 'https://static-web-media.s3.us-west-2.amazonaws.com/btc-pod.mp4',
    imageUrl: '/img/tokens/BTC.svg',
    symbol: 'BTC',
    label: 'Bitcoin'
  },
  [PodType.ETH]: {
    videoUrl: 'https://static-web-media.s3.us-west-2.amazonaws.com/eth-pod.mp4',
    imageUrl: '/img/tokens/wETH.svg',
    symbol: 'ETH',
    label: 'Ethereum'
  },
  [PodType.AVAX]: {
    videoUrl:
      'https://static-web-media.s3.us-west-2.amazonaws.com/avax-pod.mp4',
    imageUrl: '/img/tokens/AVAX.svg',
    symbol: 'AVAX',
    label: 'Avax'
  },
  [PodType.CAPSULE]: {
    videoUrl: '/img/pods/capsule.png',
    imageUrl: '/img/tokens/COMB.png',
    symbol: 'COMB',
    label: 'Capsule',
    isImage: true
  }
}
