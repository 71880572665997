<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 351.43 294.96"
    fill="currentColor"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Logo_1">
        <polygon
          class="cls-1"
          points="299.99 73.75 268.31 92.04 260.71 87.65 172.27 36.58 76.22 92.04 76.22 202.94 44.55 221.23 44.55 73.75 172.27 0 299.99 73.75"
        />
        <polygon
          class="cls-1"
          points="299.99 82.4 299.99 221.23 172.27 294.96 85.54 244.89 65.44 233.29 95.41 214.01 113.28 224.34 172.27 258.38 268.31 202.94 268.31 102.78 299.99 82.4"
        />
        <path
          class="cls-1"
          d="M351.43,43.23a40,40,0,0,1-28.92,30.63L319.37,82l-7.22,4.28-.13-11-2.4-4.06-5.15-8.69-9.64-5.41,7.22-4.28,8.45,1.36a39.92,39.92,0,0,1,40.93-10.91Z"
        />
        <polygon
          class="cls-2"
          points="76.22 202.94 44.55 221.23 44.55 164.15 76.22 152.94 76.22 202.94"
        />
        <polygon
          class="cls-2"
          points="95.41 214.01 113.28 224.34 85.54 244.89 65.44 233.29 95.41 214.01"
        />
        <polygon
          class="cls-2"
          points="268.31 92.04 260.71 87.65 299.99 73.75 268.31 92.04"
        />
        <polygon
          class="cls-2"
          points="268.31 102.78 299.99 82.4 299.99 85.98 268.31 109.45 268.31 102.78"
        />
        <polygon
          class="cls-1"
          points="299.98 73.75 44.56 221.23 0 195.5 299.98 73.75"
        />
        <path
          class="cls-2"
          d="M351.43,43.23a40,40,0,0,1-28.92,30.63L319.37,82l-7.22,4.28-.13-11-2.4-4.06S343.8,64.68,351.43,43.23Z"
        />
        <path
          class="cls-2"
          d="M310.5,54.14s-2.52,2.94-4.72,6c0,0,1.18-2.74,1.72-3.86l-7.74-2.16,2.29-1.36Z"
        />
      </g>
    </g>
  </svg>
</template>
