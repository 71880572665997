export interface SwitchEthereumChainParameter {
  chainId: string // A 0x-prefixed hexadecimal string
}

export interface AddEthereumChainParameter {
  chainId: string // A 0x-prefixed hexadecimal string
  chainName: string
  nativeCurrency: {
    name: string
    symbol: string // 2-6 characters long
    decimals: 18
  }
  rpcUrls: string[]
  blockExplorerUrls?: string[]
  iconUrls?: string[] // Currently ignored.
}

export interface ERC20Token {
  name: string
  decimals: number
  symbol: string
}

export enum ToastStatus {
  Info = 'info',
  Success = 'success',
  Error = 'error',
  Warning = 'warning'
}

export interface HTMLInputEvent extends Event {
  target: HTMLInputElement
}

export enum ButtonType {
  ORANGE = 'orange',
  GLOWING = 'glowing',
  DEFAULT = 'default',
  GREY = 'grey'
}
