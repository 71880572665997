export default {
  brough: {
    name: 'Broughton Hive',
    tag: 'Bzzz, pick me',
    price: 3,
    multiplier: 0.31
  },
  topBar: {
    name: 'Top Bar Hive',
    tag: 'Sweet Sweet Honey',
    price: 9,
    multiplier: 1
  },
  langstroth: {
    name: 'Langstroth Hive',
    tag: 'Can you bear it?',
    price: 30,
    multiplier: 3.5
  },
  warre: {
    name: 'Warre Hive',
    tag: 'Honey Overload!',
    price: 60,
    multiplier: 7.12
  }
}
