import BigNumberJs from 'bignumber.js'
import { ethers } from 'ethers'
import { defineStore } from 'pinia'

import { useCombApi } from '@/data/comb/combApi'
import { DEAD_ADDRESS } from '@/data/pools/pools'
import { signer, useWalletStore } from '@/store/wallet'
import { convertBigNumberToBigNumberJs } from '@/utils/blockchain'
import { isOnMainnet } from '@/utils/environment'
import { match } from '@/utils/misc/helpers'
import SmartContracts from '@/utils/SmartContracts/smartContracts'
import { getTokenSmartContract } from '@/utils/token/tokenCache'

import { getSmartContract } from '..'
import { numberToPodType, PodType, podTypeLiquidator, stringToPodType } from '.'
import { CombNftResponse, PodNft } from './types'

interface PodsState extends InitializableState {
  pods: Array<PodNft>
}

export const usePodsStore = defineStore('pods', {
  state: (): PodsState => ({
    initialization: {
      initialized: false,
      error: undefined
    },
    pods: []
  }),
  getters: {
    wallet() {
      return useWalletStore()
    },
    // Initialization
    initialized: (state): boolean => state.initialization.initialized,
    hasError: (state): boolean => state.initialization.error !== undefined,
    // Getters
    podsByType:
      (state) =>
      (podType: PodType): Array<PodNft> =>
        state.pods.filter((pod) => pod.podType === podType) as Array<PodNft>,
    podsApr:
      () =>
      (podType: PodType): number =>
        (useCombApi().zcomb.response?.apr.total ?? 0) *
        match(podType, {
          [PodType.AVAX]: 0.7,
          [PodType.CAPSULE]: 0.85,
          default: 0.6
        }),
    rawPodsApr: (): number => useCombApi().zcomb.response?.apr.total ?? 0,
    podsComb:
      () =>
      (podType: PodType): number =>
        match(podType, {
          [PodType.AVAX]: 1.4,
          [PodType.CAPSULE]: 1.15,
          default: 1.6
        })
  },
  actions: {
    // Async
    async refreshPods() {
      // Check for account and initialization
      if (!this.wallet.address) return

      try {
        if (isOnMainnet) {
          const pods: CombNftResponse[] = await (
            await fetch(
              `https://link.comb.financial/api/pods/${this.wallet.address}`
            )
          ).json()
          const prices: {
            address: string
            price: number
          }[] = (
            await (
              await fetch('https://comb-defi-services.vercel.app/api/prices')
            ).json()
          )['data']['prices']

          const initializedPods: Array<PodNft> = []
          for (let i = 0; i < pods.length; i++) {
            const element: CombNftResponse = pods[i]
            const tokenId = element.id

            const podsManager: ethers.Contract = getSmartContract(
              SmartContracts.PodsManager
            )
            const podsHelper: ethers.Contract = getSmartContract(
              SmartContracts.PodsHelper,
              signer
            )
            const veComb: ethers.Contract = getSmartContract(
              SmartContracts.veCOMB
            )

            // Find the pod data from the tokenId
            const [podType, wallet, , depositor] = await podsManager.wallets(
              tokenId
            )

            // Statically call the claiming of rewards to see the claimed reward amount
            const type: PodType = numberToPodType(podType)!
            const liquidator: string = podTypeLiquidator(type)

            let rewards: BigNumberJs
            let rawRewards: BigNumberJs
            try {
              rewards = convertBigNumberToBigNumberJs(
                await podsHelper.callStatic['claimPod(uint256,address)'](
                  tokenId,
                  liquidator
                )
              )
              rawRewards = rewards
              // Make sure to conver the FTM rewards to BTC or ETH
              let pricingAsset
              switch (podType) {
                case 1:
                  pricingAsset = '0x321162Cd933E2Be498Cd2267a90534A804051b11'
                  break
                case 2:
                  pricingAsset = '0x695921034f0387eAc4e11620EE91b1b15A6A09fE'
                  break
                case 3:
                  pricingAsset = '0x28a92dde19D9989F39A49905d7C9C2FAc7799bDf'
                  break
                case 4:
                  pricingAsset = '0xae45a827625116d6c0c40b5d7359ecf68f8e9afd'
                  break
              }

              if (pricingAsset) {
                const assetPrice = prices.find(
                  (e) => e.address.toLowerCase() == pricingAsset.toLowerCase()
                )?.price
                const ftmPrice = prices.find(
                  (e) =>
                    e.address.toLowerCase() ==
                    '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83'.toLowerCase()
                )?.price

                if (assetPrice && ftmPrice) {
                  rewards = rewards.multipliedBy(ftmPrice / assetPrice, 10)
                }
              }
            } catch (error) {
              console.log(error)
              rewards = new BigNumberJs(0)
              rawRewards = rewards
            }

            // Feedable rewards will always be in COMB
            let feedableRewards: BigNumberJs
            try {
              const [pid, masterchef] = await getTokenSmartContract(
                depositor,
                SmartContracts.IPodDepositor.abi
              ).harvestData()
              feedableRewards = convertBigNumberToBigNumberJs(
                await new ethers.Contract(
                  wallet,
                  SmartContracts.PodWallet.abi,
                  signer
                ).callStatic.harvest(pid, masterchef)
              )
            } catch (error) {
              feedableRewards = new BigNumberJs(0)
            }

            // Find the zCOMB balance of the PodWallet
            const lockedZComb: BigNumberJs = convertBigNumberToBigNumberJs(
              await veComb.balanceOf(wallet)
            )
            const lockedComb = convertBigNumberToBigNumberJs(
              (await veComb.locked(wallet))[0]
            )

            // Find
            initializedPods.push({
              podType: stringToPodType(type)!,
              tokenId: tokenId.toString(),
              podWalletAddress: wallet,
              rawRewards: rawRewards,
              rewards: rewards,
              feedableRewards: feedableRewards,
              lockedZComb: lockedZComb,
              lockedComb: lockedComb
            })
          }

          this.pods = initializedPods
        } else {
          this.pods = [
            {
              podType: PodType.CAPSULE,
              tokenId: '0',
              podWalletAddress: DEAD_ADDRESS,
              rawRewards: new BigNumberJs(0),
              rewards: new BigNumberJs(0),
              feedableRewards: new BigNumberJs(0),
              lockedZComb: new BigNumberJs(0),
              lockedComb: new BigNumberJs(0)
            },
            {
              podType: PodType.CAPSULE,
              tokenId: '1',
              podWalletAddress: DEAD_ADDRESS,
              rawRewards: new BigNumberJs(0),
              rewards: new BigNumberJs(0),
              feedableRewards: new BigNumberJs(0),
              lockedZComb: new BigNumberJs(0),
              lockedComb: new BigNumberJs(0)
            }
          ]
        }
        this.initialization.initialized = true
      } catch (error) {
        this.initialization.error = error
      }
    },
    async hardRefreshPods(): Promise<void> {
      this.initialization.initialized = false
      await this.refreshPods()
    },
    resetPods() {
      this.pods = []
    }
  }
})
