import { isOnMainnet } from '@/utils/environment'

export const DEAD_ADDRESS = '0x0000000000000000000000000000000000000000'
export const NO_BEEFY_IDENTIFIER = 'na'
export const COMB_IDENTIFIER = 'comb-finance'

export interface PoolData {
  poolId: string
  poolIndex: number
  pairAddress: string
  pairName: string
  icons: Array<string>
  breakdownData: any
}

export interface PoolCategory {
  icon: string
  router?: string
  isRetired?: boolean
  pools: Omit<PoolData, 'breakdowndata'>[]
}

export enum BreakdownType {
  gauge,
  multi,
  pair
}

export enum DexType {
  beets,
  spooky,
  spirit,
  protofi
}

export interface BreakdownData {
  liquidityLink?: string
  depositFee?: number
  showMigrate?: {
    toPid: number
    toToken: string
    type: number
    buttonLabel?: string
  }
}

export const pools: { [category: string]: PoolCategory } = isOnMainnet
  ? {
      Velocimeter: {
        router: '',
        icon: '/img/pools/velocimeter.png',
        pools: [
          {
            poolId: 'velocimeterFvmFtm',
            poolIndex: 28,
            pairAddress: '0x0E8f117a563Be78Eb5A391A066d0d43Dd187a9E0',
            pairName: 'FVM/FTM',
            icons: ['/img/tokens/FVM.png', '/img/tokens/FTM.svg'],
            breakdownData: {
              liquidityLink:
                'https://fvm.exchange/liquidity/0x628e61164d16E08812B3a1421B4a0Be734EC4842'
            }
          },
          {
            poolId: 'velocimeterMpxFtm',
            poolIndex: 29,
            pairAddress: '0xF8eed2665FD11a8431fc41b2582fD5E72a1606f0',
            pairName: 'MPX/FTM',
            icons: ['/img/tokens/MPX.webp', '/img/tokens/FTM.svg'],
            breakdownData: {
              liquidityLink:
                'https://www.fvm.exchange/liquidity/0xf8eed2665fd11a8431fc41b2582fd5e72a1606f0/0xf89f367e0225fe68c7c28fad0badc7f569987cfe'
            }
          }
        ]
      },
      Equalizer: {
        router: '',
        icon: '/img/tokens/EQUAL.webp',
        pools: [
          {
            poolId: 'equalizerCombFtm',
            poolIndex: 27,
            pairAddress: '0x628e61164d16E08812B3a1421B4a0Be734EC4842',
            pairName: 'COMB/FTM',
            icons: ['/img/tokens/COMB.png', '/img/tokens/FTM.svg'],
            breakdownData: {
              liquidityLink:
                'https://equalizer.exchange/liquidity/0x628e61164d16E08812B3a1421B4a0Be734EC4842'
            }
          },
          {
            poolId: 'equalizerEqualFtm',
            poolIndex: 21,
            pairAddress: '0x3d6c56f6855b7Cc746fb80848755B0a9c3770122',
            pairName: 'EQUAL/FTM',
            icons: ['/img/tokens/EQUAL.webp', '/img/tokens/FTM.svg'],
            breakdownData: {
              liquidityLink:
                'https://equalizer.exchange/liquidity/0x3d6c56f6855b7Cc746fb80848755B0a9c3770122'
            }
          },
          {
            poolId: 'equalizerOathFtm',
            poolIndex: 22,
            pairAddress: '0x558DCA97c224851Cf428cbF244bEC0b897642efC',
            pairName: 'OATH/FTM',
            icons: ['/img/tokens/OATH.webp', '/img/tokens/FTM.svg'],
            breakdownData: {
              liquidityLink:
                'https://equalizer.exchange/liquidity/0x558DCA97c224851Cf428cbF244bEC0b897642efC'
            }
          },
          {
            poolId: 'equalizerMorphexFtm',
            poolIndex: 23,
            pairAddress: '0xdE26e98d868FE02fFfb6DF26E638995124d3Ca13',
            pairName: 'MPX/FTM',
            icons: ['/img/tokens/MPX.webp', '/img/tokens/FTM.svg'],
            breakdownData: {
              liquidityLink:
                'https://equalizer.exchange/liquidity/0xdE26e98d868FE02fFfb6DF26E638995124d3Ca13'
            }
          },
          {
            poolId: 'equalizerRingFtm',
            poolIndex: 24,
            pairAddress: '0x8418BAb773ABF430907b39B7dB6A6F8318A9210e',
            pairName: 'RING/USDC',
            icons: ['/img/tokens/RING.webp', '/img/tokens/USDC.svg'],
            breakdownData: {
              liquidityLink:
                'https://equalizer.exchange/liquidity/0x8418BAb773ABF430907b39B7dB6A6F8318A9210e'
            }
          }
        ]
      },
      Beethoven: {
        router: '',
        icon: '/img/tokens/beethoven.png',
        pools: [
          {
            poolId: 'beetsQuartet',
            poolIndex: 5,
            pairAddress: '0xf3A602d30dcB723A74a0198313a7551FEacA7DAc',
            pairName: 'A Late Quartet',
            icons: [
              '/img/tokens/USDC.svg',
              '/img/tokens/FTM.svg',
              '/img/tokens/BTC.svg',
              '/img/tokens/wETH.svg'
            ],
            breakdownData: {
              liquidityLink:
                'https://beets.fi/#/pool/0xf3a602d30dcb723a74a0198313a7551feaca7dac00010000000000000000005f/invest'
            }
          },
          {
            poolId: 'beetsStardust',
            poolIndex: 6,
            pairAddress: '0xD163415BD34EF06f57C58D2AEd5A5478AfB464cC',
            pairName: 'Stardust & MIM',
            icons: [
              '/img/tokens/fUSDT.svg',
              '/img/tokens/USDC.svg',
              '/img/tokens/MIM.svg'
            ],
            breakdownData: {
              liquidityLink:
                'https://beets.fi/#/pool/0xd163415bd34ef06f57c58d2aed5a5478afb464cc00000000000000000000000e/invest'
            }
          },
          {
            poolId: 'beetsFbeetsLegacy',
            pairAddress: '0xfcef8a994209d6916eb2c86cdd2afd60aa6f54b1',
            poolIndex: 9,
            pairName: 'fBEETs ',
            icons: ['/img/tokens/FBEETS.png'],
            breakdownData: {
              liquidityLink: 'https://v1.beets.fi/#/stake'
            }
          },
          // {
          //   poolId: 'beetsFbeets',
          //   pairAddress: '0x9e4341acef4147196e99d648c5e43b3fc9d02678',
          //   poolIndex: 25,
          //   pairName: 'fBEETs',
          //   icons: ['/img/tokens/FBEETS.png'],
          //   breakdownData: {
          //     liquidityLink: 'https://v1.beets.fi/#/stake'
          //   }
          // },
          {
            poolId: 'beetsSftmxFtm',
            pairAddress: '0xc0064b291bd3D4ba0E44ccFc81bF8E7f7a579cD2',
            poolIndex: 19,
            pairName: 'Two Fantoms',
            icons: ['/img/tokens/SFTMX.webp', '/img/tokens/yvFTM.png'],
            breakdownData: {
              liquidityLink:
                'https://beets.fi/#/pool/0xc0064b291bd3d4ba0e44ccfc81bf8e7f7a579cd200000000000000000000042c/invest'
            }
          }
        ]
      },

      SpookySwap: {
        router: '0xF491e7B69E4244ad4002BC14e878a34207E38c29',
        icon: '/img/tokens/BOO.svg',
        pools: [
          {
            poolId: 'spookyBooFtm',
            pairAddress: '0xEc7178F4C41f346b2721907F5cF7628E388A7a58',
            poolIndex: 3,
            pairName: 'BOO/FTM',
            icons: ['/img/tokens/BOO.svg', '/img/tokens/FTM.svg'],
            breakdownData: {
              liquidityLink:
                'https://spooky.fi/#/add/0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE/FTM'
            }
          },
          {
            poolId: 'spookyFtmUsdc',
            poolIndex: 4,
            pairAddress: '0x2b4c76d0dc16be1c31d4c1dc53bf9b45987fc75c',
            pairName: 'FTM/USDC',
            icons: ['/img/tokens/FTM.svg', '/img/tokens/USDC.svg'],
            breakdownData: {
              liquidityLink:
                'https://spooky.fi/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/FTM'
            }
          },
          {
            poolId: 'spookyCrvFtm',
            poolIndex: 18,
            pairAddress: '0xB471Ac6eF617e952b84C6a9fF5de65A9da96C93B',
            pairName: 'FTM/CRV',
            icons: ['/img/tokens/FTM.svg', '/img/tokens/CRV.webp'],
            breakdownData: {
              liquidityLink:
                'https://spooky.fi/#/add/0x1E4F97b9f9F913c46F1632781732927B9019C68b/FTM'
            }
          },
          {
            poolId: 'screamScreamFtm',
            pairAddress: '0x30872e4fc4edbFD7a352bFC2463eb4fAe9C09086',
            poolIndex: 10,
            pairName: 'SCREAM/FTM',
            icons: ['/img/tokens/SCREAM.webp', '/img/tokens/FTM.svg'],
            breakdownData: {
              liquidityLink:
                'https://spooky.fi/#/add/0xe0654C8e6fd4D733349ac7E09f6f23DA256bF475/FTM',
              depositFee: 0.5
            }
          }
        ]
      },
      SpiritSwapV2: {
        icon: '/img/pools/spiritswap.png',
        router: '0x09855b4ef0b9df961ed097ef50172be3e6f13665',
        pools: [
          {
            poolId: 'spiritV2CombFtm',
            poolIndex: 11,
            pairName: 'COMB/FTM',
            pairAddress: '0x244af40a992b256a54891d9676b1d29be46b1449',
            icons: ['/img/tokens/COMB.png', '/img/tokens/FTM.svg'],
            breakdownData: {
              liquidityLink:
                'https://beta.spiritswap.finance/liquidity/FTM/COMB/variable',
              showMigrate: {
                toPid: 27,
                toToken: '0x628e61164d16E08812B3a1421B4a0Be734EC4842',
                type: 2
              }
            }
          },
          {
            poolId: 'spiritV2FusdtFtm',
            poolIndex: 14,
            pairAddress: '0x842C44870eD021f070938D077ca2Cf2DC474eCa6',
            pairName: 'FTM/FUSDT',
            icons: ['/img/tokens/FTM.svg', '/img/tokens/fUSDT.svg'],
            breakdownData: {
              liquidityLink:
                'https://beta.spiritswap.finance/liquidity/FUSDT/FTM/variable'
            }
          },
          {
            poolId: 'spiritV2FraxUsdc',
            poolIndex: 15,
            pairAddress: '0x55167b5917A47EEafE2b1afEd12Bcb3Aaab54255',
            pairName: 'FRAX/USDC',
            icons: ['/img/tokens/FRAX.png', '/img/tokens/USDC.svg'],
            breakdownData: {
              liquidityLink:
                'https://beta.spiritswap.finance/liquidity/FRAX/USDC/stable'
            }
          },
          {
            poolId: 'spiritV2UsdcDai',
            poolIndex: 16,
            pairAddress: '0x9692129bb91b4E3942C0f17B0bdCC582Ff22fFB5',
            pairName: 'DAI/USDC',
            icons: ['/img/tokens/DAI.svg', '/img/tokens/USDC.svg'],
            breakdownData: {
              liquidityLink:
                'https://beta.spiritswap.finance/liquidity/DAI/USDC/stable'
            }
          },
          {
            poolId: 'spiritV2FusdtUsdc',
            poolIndex: 17,
            pairAddress: '0x40DEa26Dd3a0d549dC5Ecd4522045e8AD02f83FB',
            pairName: 'FUSDT/USDC',
            icons: ['/img/tokens/USDT.svg', '/img/tokens/USDC.svg'],
            breakdownData: {
              liquidityLink:
                'https://beta.spiritswap.finance/liquidity/FUSDT/USDC/stable'
            }
          }
        ]
      },
      SpiritSwap: {
        router: '0x16327e3fbdaca3bcf7e38f5af2599d2ddc33ae52',
        icon: '/img/tokens/spiritswap.png',
        isRetired: true,
        pools: [
          {
            poolId: 'spiritCombFtm',
            poolIndex: 0,
            pairName: 'COMB/FTM',
            pairAddress: isOnMainnet
              ? '0x95297492B1fAA6047D1D8CE982A0F5cDEB0e9482'
              : '0x1CBEFa5D5815391A63544E1829e1b6d63F387a64',
            icons: ['/img/tokens/COMB.png', '/img/tokens/FTM.svg'],
            breakdownData: {
              liquidityLink:
                'https://swap.spiritswap.finance/#/add/FTM/0xaE45a827625116d6C0C40B5D7359EcF68F8e9AFD',
              showMigrate: {
                toPid: 11,
                toToken: '0x244af40a992b256a54891d9676b1d29be46b1449',
                type: 0
              }
            }
          },
          {
            poolId: 'spiritLqdrFtm',
            poolIndex: 1,
            pairAddress: '0x4Fe6f19031239F105F753D1DF8A0d24857D0cAA2',
            pairName: 'FTM/LQDR',
            icons: ['/img/tokens/FTM.svg', '/img/tokens/LQDR.svg'],
            breakdownData: {
              liquidityLink:
                'https://swap.spiritswap.finance/#/add/FTM/0x10b620b2dbAC4Faa7D7FFD71Da486f5D44cd86f9',
              showMigrate: {
                toPid: 13,
                toToken: '0xE42Bb367c958e0E624C164f2491c37d8Fd713515',
                type: 0
              }
            }
          },
          {
            poolId: 'spiritMaiFtm',
            poolIndex: 2,
            pairAddress: '0x51Eb93ECfEFFbB2f6fE6106c4491B5a0B944E8bd',
            pairName: 'MAI/FTM',
            icons: ['/img/tokens/MAI.svg', '/img/tokens/FTM.svg'],
            breakdownData: {
              liquidityLink:
                'https://swap.spiritswap.finance/#/add/FTM/0xfB98B335551a418cD0737375a2ea0ded62Ea213b',
              showMigrate: {
                toPid: 14,
                toToken: '0x842C44870eD021f070938D077ca2Cf2DC474eCa6',
                type: 1,
                buttonLabel: 'Migrate to FUSDT/FTM'
              }
            }
          },
          {
            poolId: 'spiritV2LqdrFtm',
            poolIndex: 13,
            pairAddress: '0xE42Bb367c958e0E624C164f2491c37d8Fd713515',
            pairName: 'LQDR/FTM',
            icons: ['/img/tokens/FTM.svg', '/img/tokens/LQDR.svg'],
            breakdownData: {
              liquidityLink:
                'https://beta.spiritswap.finance/liquidity/LQDR/FTM/variable'
            }
          }
        ]
      }
    }
  : {
      SpiritSwap: {
        icon: '/img/tokens/spiritswap.png',
        pools: [
          {
            poolId: 'beetsDaiOpera',
            poolIndex: 8,
            pairName: 'COMB',
            pairAddress: '0x311ffedc9b5caffe31dd791a8604a0050b8b3516',
            icons: ['/img/tokens/COMB.png'],
            breakdownData: {
              type: BreakdownType.pair,
              dexType: DexType.spirit,
              symbols: ['comb-finance'],
              strategy: DEAD_ADDRESS,
              underlyingMasterChef: DEAD_ADDRESS,
              underlyingPoolId: 0,
              apiIdentifier: COMB_IDENTIFIER
            }
          },
          {
            poolId: 'beetsDaiOpera',
            poolIndex: 8,
            pairName: 'FTM',
            pairAddress: '0x90d2ede94318c6312d40dbeb4193f0620d0b2c42',
            icons: ['/img/tokens/FTM.svg'],
            breakdownData: {
              type: BreakdownType.pair,
              dexType: DexType.spirit,
              symbols: ['comb-finance'],
              strategy: DEAD_ADDRESS,
              underlyingMasterChef: DEAD_ADDRESS,
              underlyingPoolId: 1,
              apiIdentifier: COMB_IDENTIFIER
            }
          }
        ]
      }
    }

export const getAddressByPairName = (pairName: string): string => {
  let pairAddress = ''
  Object.entries(pools).forEach((poolItem) => {
    poolItem[1].pools.forEach((pool) => {
      pool.pairName === pairName ? (pairAddress = pool.pairAddress) : null
    })
  })
  return pairAddress
}
