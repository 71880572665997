export const ZapContracts = {
  BaseZap: {
    address: '0x01f2DA2Ea9231eAE7b1b18492E62E00903E2a150',
    ABI: [
      'function estimateZapInToken(address _from, address _to, address _router, uint _amt) external view returns (uint256, uint256)',
      'function zapIn(address _to, address routerAddr, address _recipient) external payable',
      'function zapInToken(address _from, uint amount, address _to, address routerAddr, address _recipient) external'
    ]
  },
  Zap: {
    address: '0xF0ff07d19f310abab54724a8876Eee71E338c82F',
    ABI: [
      'function estimateZapInToken(address _from, address _to, address _router, uint _amt) external view returns (uint256, uint256)',
      'function zapIn(address _to, address routerAddr, address _recipient) external payable',
      'function zapInToken(address _from, uint amount, address _to, address routerAddr, address _recipient) external'
    ]
  },
  BearLiquidator: {
    address: '0xaDa23b35aF28DD3B7Da072391d39824134978270',
    ABI: [
      'constructor()',
      'event Initialized(uint8)',
      'event OwnershipTransferred(address indexed,address indexed)',
      'function BEETS() view returns (address)',
      'function COMB() view returns (address)',
      'function WFTM() view returns (address)',
      'function initialize(address)',
      'function liquidate(address[],uint256[])',
      'function owner() view returns (address)',
      'function renounceOwnership()',
      'function settings() view returns (address)',
      'function transferOwnership(address)'
    ]
  },
  MasterChefV2Migrator: {
    address: '0x7bC80a75004888b4B019c158bd3c4A87790B4793',
    abi: [
      'constructor()',
      'event Initialized(uint8)',
      'event RoleAdminChanged(bytes32 indexed,bytes32 indexed,bytes32 indexed)',
      'event RoleGranted(bytes32 indexed,address indexed,address indexed)',
      'event RoleRevoked(bytes32 indexed,address indexed,address indexed)',
      'event SetMasterChef(address)',
      'function DEFAULT_ADMIN_ROLE() view returns (bytes32)',
      'function SETTER_ROLE() view returns (bytes32)',
      'function SPIRIT_V1_ROUTER() view returns (address)',
      'function SPIRIT_V2_ROUTER() view returns (address)',
      'function getRoleAdmin(bytes32) view returns (bytes32)',
      'function grantRole(bytes32,address)',
      'function hasRole(bytes32,address) view returns (bool)',
      'function initialize(address)',
      'function masterchef() view returns (address)',
      'function migrate(uint256,address,uint256,uint8)',
      'function renounceRole(bytes32,address)',
      'function revokeRole(bytes32,address)',
      'function setMasterChef(address)',
      'function supportsInterface(bytes4) view returns (bool)'
    ]
  }
}

export default ZapContracts
