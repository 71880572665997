import { useWalletStore } from '@/store/wallet'

export function assertType(obj, type) {
  return obj.constructor.name === type.name
}

export function sortByDesc(firstNumber, secondNumber) {
  return secondNumber - firstNumber
}

export const supportVisibleBalance = (value: string) =>
  useWalletStore().isBalanceVisible ? value : '*****'

export const delay = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms))

export const match = (expr, cases) => {
  const entries = Object.entries(cases)

  for (const [pattern, action] of entries) {
    if (expr?.toString() === pattern) {
      return typeof action === 'function' ? action() : action
    }
  }

  return cases?.default
}

export function isValidURL(str: string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return pattern.test(str)
}
