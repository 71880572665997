const DFYN_LPF = 0.003
const SUSHI_LPF = 0.003
const SPIRIT_LPF = 0.003
const QUICK_LPF = 0.003
const APEPOLY_LPF = 0.002
const COMETH_LPF = 0.005
const PCS_LPF = 0.0025
const APE_LPF = 0.002
const SPOOKY_LPF = 0.002
const PROTO_LPF = 0.0015
const JOE_LPF = 0.003
const SOLAR_LPF = 0.0025
const FUSEFI_LPF = 0.003
const NET_LPF = 0.003
const PANGOLIN_LPF = 0.003
const TETHYS_LPF = 0.002

const MILLISECONDS_PER_SECOND = 1000
const SECONDS_PER_MINUTE = 60
const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * 60
const SECONDS_PER_DAY = SECONDS_PER_HOUR * 24
const SECONDS_PER_WEEK = SECONDS_PER_DAY * 7
const SECONDS_PER_YEAR = SECONDS_PER_DAY * 365

const DAYS_PER_YEAR = 365
const DAYS_OF_TWO_YEARS = DAYS_PER_YEAR * 2

const LONG_SYNC_TIME_IN_MILLISECONDS = 60000
const SHORT_SYNC_TIME_IN_MILLISECONDS = 30000

enum LOADING_STATUS {
  OK = 'ok',
  ERROR = 'error',
  LOADING = 'loading',
  NEVER = 'never'
}

export {
  APE_LPF,
  APEPOLY_LPF,
  COMETH_LPF,
  DAYS_OF_TWO_YEARS,
  DAYS_PER_YEAR,
  DFYN_LPF,
  FUSEFI_LPF,
  JOE_LPF,
  LOADING_STATUS,
  LONG_SYNC_TIME_IN_MILLISECONDS,
  MILLISECONDS_PER_SECOND,
  NET_LPF,
  PANGOLIN_LPF,
  PCS_LPF,
  PROTO_LPF,
  QUICK_LPF,
  SECONDS_PER_DAY,
  SECONDS_PER_HOUR,
  SECONDS_PER_MINUTE,
  SECONDS_PER_WEEK,
  SECONDS_PER_YEAR,
  SHORT_SYNC_TIME_IN_MILLISECONDS,
  SOLAR_LPF,
  SPIRIT_LPF,
  SPOOKY_LPF,
  SUSHI_LPF,
  TETHYS_LPF
}
