import './assets/style.scss'

import { createPinia } from 'pinia'
import VCalendar from 'v-calendar'
import { createApp } from 'vue'
import vfmPlugin from 'vue-final-modal'
import VueApexCharts from 'vue3-apexcharts'
import Popper from 'vue3-popper'

import App from './App.vue'
import toastNotificationsPlugin from './plugins/toastNotificationsPlugin'
import router from './router'

const app = createApp(App)

app
  .use(toastNotificationsPlugin)
  .use(vfmPlugin)
  .use(router)
  .use(createPinia())
  .use(VueApexCharts)
  .use(VCalendar, {})
  .mount('#app')
