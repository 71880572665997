import { isOnMainnet } from '@/utils/environment'

const CombContracts = {
  NectarCompounder: {
    address: isOnMainnet
      ? '0x2cf2891A7a05aCa37d817071e2Ac63e425C66019'
      : '0xa626d5Ac4eac6BB997bAd1926a4d3605ba5a2D2c',
    ABI: [
      'function compound() public',
      'function createNodeWithCompoundingTokens(string memory name, uint256 index, bool custom) public',
      'function claimAndCreateLock() external returns (uint256)',
      'function claimAndFundLock() external returns (uint256)',
      'function unwrapAndFundLock() external returns (uint256)',
      'function unwrapAndFundLockFor(address recipient, uint256 amount) external returns (uint256)'
    ]
  },
  NodeRewardManagement: {
    address: isOnMainnet
      ? '0x3e844BF3F21eFEB4B8E9c32cDD772822DB07F306'
      : '0x00466122E49A8b5D1c652F767B29a42bb10bDF67',
    ABI: [
      // returns the current rewards tax for an address with a certain amount (3 decimals)
      'function getNodesRewardsTax(address account, uint256 amount) public view returns (uint256)',
      // returns the current rewards tax for an address with a certain amount (3 decimals)
      'function regressiveTax() public view returns (uint256, uint256, uint256, uint256)',
      // returns the reward for a base node every distribution (multiply by 6 for a days worth)
      'function rewardPerNode() public view returns (uint256)',
      // returns the time in which the next distribution may be executed
      'function nextDistribution() public view returns (uint256) ',
      // returns the price of a base node
      'function nodePrice() public view returns (uint256)',
      // returns all the names for all of the nodes an address owns, separated with a #
      'function getNodeNames(address account) public view returns (string memory) ',
      // returns all the timestamps in blocktime for all of the nodes an address owns, separated with a #
      'function getNodesCreationTime(address addy) public view returns (string memory)',
      // returns all the multipliers for all of the nodes an address owns, separated with a # (100#100#100#100)
      'function getNodesMultipliers(address addy) public view returns (string memory)',
      // returns all the metadata for all of the nodes an address owns, separated with a # (meta#meta#meta#meta)
      'function getNodesMetadata(address addy) public view returns (string memory)',
      // get the number of nodes an address owns
      'function getNodeNumberOf(address account) public view returns (uint256)',
      // returns the rewards amount of a certain address
      'function getRewardAmountOf(address account) public view returns (uint256)',
      // returns the total number of nodes created
      'function totalNodesCreated() public view returns (uint256)',
      'function distributeRewards() public'
    ]
  },
  HiveBeacon: {
    address: isOnMainnet
      ? '0xC89c5096d08F3F8238b5Fb43484AD6F5FF52F9F6'
      : '0xc14B6077EBb32b23CF566364cb83f59397eC7c5f',
    ABI: [
      // claims all of the rewards and transfers tokens
      'function cashoutAll() public',
      // create a node with tokens, if custom is true and index is within bounds of customs management, then the price of the node
      // defer to the price as stated onto the custom management
      'function createNodeWithTokens(string memory name, uint256 index, bool custom) public'
    ]
  },
  NECTAR: {
    address: isOnMainnet
      ? '0xcEddEC9a3134a440E77C7DcAD6F35fdF398A49Fc'
      : '0x07448069274a8259872EC0729744D10b96c8F1dE',
    ABI: [
      {
        inputs: [
          {
            internalType: 'address',
            name: 'underlyingToken_',
            type: 'address'
          },
          { internalType: 'address', name: 'servicePool_', type: 'address' },
          {
            internalType: 'uint256',
            name: 'emergencyFee_',
            type: 'uint256'
          }
        ],
        stateMutability: 'nonpayable',
        type: 'constructor'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'owner',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'spender',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'value',
            type: 'uint256'
          }
        ],
        name: 'Approval',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'sender',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'Deposit',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'recipient',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'fee',
            type: 'uint256'
          },
          {
            indexed: true,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'Feed',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'bytes32',
            name: 'role',
            type: 'bytes32'
          },
          {
            indexed: true,
            internalType: 'bytes32',
            name: 'previousAdminRole',
            type: 'bytes32'
          },
          {
            indexed: true,
            internalType: 'bytes32',
            name: 'newAdminRole',
            type: 'bytes32'
          }
        ],
        name: 'RoleAdminChanged',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'bytes32',
            name: 'role',
            type: 'bytes32'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'account',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'sender',
            type: 'address'
          }
        ],
        name: 'RoleGranted',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'bytes32',
            name: 'role',
            type: 'bytes32'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'account',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'sender',
            type: 'address'
          }
        ],
        name: 'RoleRevoked',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'from',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'to',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'value',
            type: 'uint256'
          }
        ],
        name: 'Transfer',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'recipient',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'Withdrawal',
        type: 'event'
      },
      {
        inputs: [],
        name: 'COMPOUNDER_ROLE',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'DEFAULT_ADMIN_ROLE',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'address', name: 'owner', type: 'address' },
          {
            internalType: 'address',
            name: 'spender',
            type: 'address'
          }
        ],
        name: 'allowance',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'address', name: 'spender', type: 'address' },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'approve',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'decimals',
        outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'address', name: 'spender', type: 'address' },
          {
            internalType: 'uint256',
            name: 'subtractedValue',
            type: 'uint256'
          }
        ],
        name: 'decreaseAllowance',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'emergencyFee',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'emergencyUnwrap',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
        name: 'getRoleAdmin',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'bytes32', name: 'role', type: 'bytes32' },
          {
            internalType: 'address',
            name: 'account',
            type: 'address'
          }
        ],
        name: 'grantRole',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'bytes32', name: 'role', type: 'bytes32' },
          {
            internalType: 'address',
            name: 'account',
            type: 'address'
          }
        ],
        name: 'hasRole',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'address', name: 'spender', type: 'address' },
          {
            internalType: 'uint256',
            name: 'addedValue',
            type: 'uint256'
          }
        ],
        name: 'increaseAllowance',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'name',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'bytes32', name: 'role', type: 'bytes32' },
          {
            internalType: 'address',
            name: 'account',
            type: 'address'
          }
        ],
        name: 'renounceRole',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'bytes32', name: 'role', type: 'bytes32' },
          {
            internalType: 'address',
            name: 'account',
            type: 'address'
          }
        ],
        name: 'revokeRole',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'servicePool',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [{ internalType: 'uint256', name: 'value', type: 'uint256' }],
        name: 'setEmergencyFee',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [{ internalType: 'address', name: 'value', type: 'address' }],
        name: 'setServicePool',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }
        ],
        name: 'supportsInterface',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'symbol',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'totalSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'address', name: 'recipient', type: 'address' },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'transfer',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'address', name: 'sender', type: 'address' },
          {
            internalType: 'address',
            name: 'recipient',
            type: 'address'
          },
          { internalType: 'uint256', name: 'amount', type: 'uint256' }
        ],
        name: 'transferFrom',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'underlyingToken',
        outputs: [
          { internalType: 'contract IERC20', name: '', type: 'address' }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'unwrap',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'address', name: 'user', type: 'address' },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          },
          { internalType: 'address', name: 'recipient', type: 'address' }
        ],
        name: 'unwrapFor',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'wrap',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'address', name: 'user', type: 'address' },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          },
          { internalType: 'address', name: 'recipient', type: 'address' }
        ],
        name: 'wrapFor',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function'
      }
    ]
  },
  COMB: {
    address: isOnMainnet
      ? '0xaE45a827625116d6C0C40B5D7359EcF68F8e9AFD'
      : '0x311FFEDc9b5cAfFE31dD791A8604a0050b8b3516'
  },
  veCOMB: {
    address: isOnMainnet
      ? '0xDECCe40d4176aBEfb4c709B2220c8396fE710cf7'
      : '0x7afC863Bb5d2385F54C49A5306d47a30BA00670A',
    ABI: [
      'event CommitOwnership(address admin)',
      'event ApplyOwnership(address admin)',
      'event Deposit(address indexed provider, uint256 value, uint256 indexed locktime, int128 type, uint256 ts)',
      'event Withdraw(address indexed provider, uint256 value, uint256 ts)',
      'event Supply(uint256 prevSupply, uint256 supply)',
      'constructor(address token_addr, string _name, string _symbol, string _version)',
      'function commit_transfer_ownership(address addr)',
      'function apply_transfer_ownership()',
      'function commit_smart_wallet_checker(address addr)',
      'function apply_smart_wallet_checker()',
      'function get_last_user_slope(address addr) view returns (int128)',
      'function user_point_history__ts(address _addr, uint256 _idx) view returns (uint256)',
      'function locked__end(address _addr) view returns (uint256)',
      'function has_lock(address _addr) view returns (bool)',
      'function checkpoint()',
      'function deposit_for(address _addr, uint256 _value)',
      'function create_lock(uint256 _value, uint256 _unlock_time)',
      'function increase_amount(uint256 _value)',
      'function increase_unlock_time(uint256 _unlock_time)',
      'function withdraw()',
      'function balanceOf(address addr) view returns (uint256)',
      'function balanceOfAt(address addr, uint256 _block) view returns (uint256)',
      'function totalSupply() view returns (uint256)',
      'function totalSupplyAt(uint256 _block) view returns (uint256)',
      'function changeController(address _newController)',
      'function token() view returns (address)',
      'function supply() view returns (uint256)',
      'function locked(address arg0) view returns (int128 amount, uint256 end)',
      'function epoch() view returns (uint256)',
      'function reset_lock_for(address)',
      'function point_history(uint256 arg0) view returns (int128 bias, int128 slope, uint256 ts, uint256 blk)',
      'function user_point_history(address arg0, uint256 arg1) view returns (int128 bias, int128 slope, uint256 ts, uint256 blk)',
      'function user_point_epoch(address arg0) view returns (uint256)',
      'function slope_changes(uint256 arg0) view returns (int128)',
      'function controller() view returns (address)',
      'function transfersEnabled() view returns (bool)',
      'function name() view returns (string)',
      'function symbol() view returns (string)',
      'function version() view returns (string)',
      'function decimals() view returns (uint256)',
      'function future_smart_wallet_checker() view returns (address)',
      'function smart_wallet_checker() view returns (address)',
      'function admin() view returns (address)',
      'function future_admin() view returns (address)'
    ]
  },
  FeeDistributor: {
    address: isOnMainnet
      ? '0x624e2A2626e61627B53c402682C125C6CE45e032'
      : '0x2F9347E59Bcc8cB765B5E346faBf31d3ae48e634',
    ABI: [
      'event CommitAdmin(address admin)',
      'event ApplyAdmin(address admin)',
      'event ToggleAllowCheckpointToken(bool toggle_flag)',
      'event CheckpointToken(uint256 time, uint256 tokens)',
      'event Claimed(address indexed recipient, uint256 amount, uint256 claim_epoch, uint256 max_epoch)',
      isOnMainnet
        ? 'constructor(address _voting_escrow, uint256 _start_time, address[9] _token, address _admin, address _emergency_return)'
        : 'constructor(address _voting_escrow, uint256 _start_time, address[2] _token, address _admin, address _emergency_return)',
      'function checkpoint_token() @47578888',
      'function ve_for_at(address _user, uint256 _timestamp) view returns (uint256) @249417',
      'function checkpoint_total_supply() @73909685',
      isOnMainnet
        ? 'function claim() returns (uint256[9])'
        : 'function claim() returns (uint256[2])',
      'function claim_many(address[20] _receivers) returns (bool)',
      'function last_claimed_date(address _addr) view returns (uint256)',
      'function burn(address _coin) returns (bool) @47580832',
      'function commit_admin(address _addr) @37928',
      'function apply_admin() @39564',
      'function toggle_allow_checkpoint_token() @38703',
      'function kill_me() @65480',
      'function recover_balance(address _coin) returns (bool) @6911',
      'function set_emergency_return(address _addr) returns (bool) @36773',
      'function get_timestamp() view returns (uint256) @800',
      'function start_time() view returns (uint256) @1631',
      'function time_cursor() view returns (uint256)',
      'function user_epoch_of(address arg0) view returns (uint256) @1936',
      'function last_token_times(uint256 arg0) view returns (uint256) @1860',
      'function tokens_per_day(uint256 arg0, uint256 arg1) view returns (uint256) @2005',
      'function voting_escrow() view returns (address) @1811',
      'function tokens(uint256 arg0) view returns (address) @1950',
      'function total_received() view returns (uint256) @1871',
      'function token_last_balances(uint256 arg0) view returns (uint256) @2010',
      'function ve_supply(uint256 arg0) view returns (uint256) @2040',
      'function admin() view returns (address) @1961',
      'function future_admin() view returns (address) @1991',
      'function can_checkpoint_token() view returns (bool) @2021',
      'function emergency_return() view returns (address) @2051',
      'function is_killed() view returns (bool) @2081'
    ]
  },
  Strategy: {
    ABI: [
      'constructor(address depositor, address lp, address token0, address token1, uint256 pid)',
      'function balanceOf() view returns (uint256)',
      'function balanceOfPool() view returns (uint256)',
      'function balanceOfWant() view returns (uint256)',
      'function boo() view returns (address)',
      'function deposit()',
      'function depositor() view returns (address)',
      'function getHarvestable() view returns (uint256)',
      'function governance() view returns (address)',
      'function harvest()',
      'function harvesters(address) view returns (bool)',
      'function masterChef() view returns (address)',
      'function masterchef() view returns (address)',
      'function poolId() view returns (uint256)',
      'function revokeHarvesters(address[] _harvesters)',
      'function rewardToken() view returns (address)',
      'function setDepositor(address _depositor)',
      'function setGovernance(address _governance)',
      'function token0() view returns (address)',
      'function token1() view returns (address)',
      'function want() view returns (address)',
      'function whitelistHarvesters(address[] _harvesters)',
      'function withdraw(uint256 _amount) returns (uint256)',
      'function withdraw(address _asset) returns (uint256 balance)',
      'function withdrawAll() returns (uint256 balance)'
    ]
  },
  Gauge: {
    ABI: ['function balanceOf() external view returns (uint256)']
  },
  MasterChef: {
    address: isOnMainnet
      ? '0x6e547e6Ab873146eA1E4A12499b0e98Bc18Ea222'
      : '0x018E36CCaeCf3279f226E84AC3530604D499f213',
    ABI: [
      'event Deposit(address indexed user, uint256 indexed pid, uint256 amount, address indexed to)',
      'event DepositToLiquidDepositor(uint256 amount, address token)',
      'event EmergencyWithdraw(address indexed user, uint256 indexed pid, uint256 amount, address indexed to)',
      'event Harvest(address indexed user, uint256 indexed pid, uint256 amount)',
      'event LogInit()',
      'event LogPoolAddition(uint256 indexed pid, uint256 allocPoint, address indexed lpToken, address indexed rewarder)',
      'event LogSetPool(uint256 indexed pid, uint256 allocPoint, address indexed rewarder, bool overwrite)',
      'event LogUpdatePool(uint256 indexed pid, uint256 lastRewardBlock, uint256 lpSupply, uint256 accCombPerShare)',
      'event OwnershipTransferred(address indexed previousOwner, address indexed newOwner)',
      'event Withdraw(address indexed user, uint256 indexed pid, uint256 amount, address indexed to)',
      'event WithdrawFromLiquidDepositor(uint256 amount, address token)',
      'function ACC_COMB_PRECISION() view returns (uint256)',
      'function COMB() view returns (address)',
      'function MASTERCHEF_COMB_PER_BLOCK() view returns (uint256)',
      'function MASTER_CHEF() view returns (address)',
      'function MASTER_PID() view returns (uint256)',
      'function add(uint256 allocPoint, address _lpToken, address _rewarder, address _strategy, uint256 _depositFee)',
      'function combPerBlock() view returns (uint256 amount)',
      'function deposit(uint256 pid, uint256 amount, address to)',
      'function emergencyWithdraw(uint256 pid, address to)',
      'function feeAddress() view returns (address)',
      'function feeAddresses(uint256) view returns (address)',
      'function harvest(uint256 pid, address to)',
      'function harvestFromMasterChef()',
      'function harvestFromStrategy(uint256 pid)',
      'function init(address dummyToken)',
      'function initialize(address _comb, address _feeAddress, address _treasury)',
      'function liquidDepositor() view returns (address)',
      'function lpToken(uint256) view returns (address)',
      'function massHarvestFromStrategies()',
      'function massUpdatePools(uint256[] pids)',
      'function owner() view returns (address)',
      'function pendingComb(uint256 _pid, address _user) view returns (uint256 pending)',
      'function poolInfo(uint256) view returns (uint256 accCombPerShare, uint256 lastRewardBlock, uint256 allocPoint, uint256 depositFee)',
      'function poolLength() view returns (uint256 pools)',
      'function renounceOwnership()',
      'function rewarder(uint256) view returns (address)',
      'function set(uint256 _pid, uint256 _allocPoint, address _rewarder, address _strategy, uint256 _depositFee, bool overwrite)',
      'function setFeeAddress(address _feeAddress)',
      'function setFeeAddresses(uint256 pid, address _feeAddress)',
      'function setMasterChef(address masterChef, uint256 masterPid, uint256 masterChefCombPerBlock)',
      'function setTreasuryAddress(address _treasuryAddress)',
      'function strategies(uint256) view returns (address)',
      'function totalAllocPoint() view returns (uint256)',
      'function transferOwnership(address newOwner)',
      'function treasury() view returns (address)',
      'function updatePool(uint256 pid) returns (tuple(uint256 accCombPerShare, uint256 lastRewardBlock, uint256 allocPoint, uint256 depositFee) pool)',
      'function userInfo(uint256, address) view returns (uint256 amount, int256 rewardDebt)',
      'function withdraw(uint256 pid, uint256 amount, address to)',
      'function withdrawAndHarvest(uint256 pid, uint256 amount, address to)'
    ]
  }
}

export default CombContracts
