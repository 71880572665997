import { ethers } from 'ethers'
import { DateTime } from 'luxon'
import { defineStore } from 'pinia'

import {
  fetchPriceFromOracleEndpoint,
  retrieveAddressByIdentifier
} from '@/data/tokenAddressBook'
import { createToast } from '@/plugins/toastNotificationsPlugin'
import { feeDistributorContract, useStore, veCOMBContract } from '@/store/index'
import { useWalletStore } from '@/store/wallet'
import { fantomChainId } from '@/utils/blockchain'
import { SECONDS_PER_YEAR } from '@/utils/constants'
import { getReportedMessage } from '@/utils/errorHandling'
import { claimEarnings, claimEarningsType } from '@/utils/zcomb/claimEarnings'

import { ToastStatus } from '../../types'

const TOKEN_COUNT = 9
const MINUMUM_CLAIM_DATE = 1
const SYNC_TIME_IN_MILLISECONDS = 60000

let priceSyncInterval: ReturnType<typeof setInterval> | null = null

export interface ZcombState {
  hasLock: boolean
  lastClaimDate: number
  totalLockedComb: ethers.BigNumber
  totalzCOMB: ethers.BigNumber
  yourLockedComb: ethers.BigNumber
  yourLockUntil: DateTime | undefined
  yourzCOMB: ethers.BigNumber
  claimableEarnings: ethers.BigNumber[]
  claimableTokensUsdPrice: claimEarningsType[]
}

// NOTE: these variables are placed intentionally outside pinia store management, as these objects don't like proxies and don't work properly
// export let veCOMBContract: ethers.Contract | undefined;

export const useZcombStore = defineStore('zcomb', {
  state(): ZcombState {
    return {
      hasLock: false,
      lastClaimDate: 0,
      totalLockedComb: ethers.BigNumber.from(0),
      totalzCOMB: ethers.BigNumber.from(0),
      yourLockedComb: ethers.BigNumber.from(0),
      yourzCOMB: ethers.BigNumber.from(0),
      yourLockUntil: undefined,
      claimableEarnings: new Array(TOKEN_COUNT).fill(ethers.BigNumber.from(0)),
      claimableTokensUsdPrice: [...claimEarnings]
    }
  },
  getters: {
    store() {
      return useStore()
    },
    wallet() {
      return useWalletStore()
    },
    isLockExpired(): boolean {
      if (this.yourLockUntil === undefined) {
        return false
      }

      return this.yourLockUntil <= DateTime.now()
    },
    isClaimable(): boolean {
      return this.lastClaimDate < MINUMUM_CLAIM_DATE
    }
  },
  actions: {
    async fetchAllData() {
      // await this.wallet.waitTillConnectedToWallet()
      if (!this.wallet.address || this.wallet.chainId != fantomChainId) {
        return
      }

      this.fetchHasZCombLock()
      this.fetchLastClaimDate()
      this.fetchUserLockedComb()
      this.fetchUserzCOMB()
      this.fetchClaimableEarnings()
      this.fetchUserLockedUntil()
    },
    async resetAllData() {
      this.hasLock = false
      this.lastClaimDate = 0
      this.totalLockedComb = ethers.BigNumber.from(0)
      this.totalzCOMB = ethers.BigNumber.from(0)
      this.yourLockedComb = ethers.BigNumber.from(0)
      this.yourzCOMB = ethers.BigNumber.from(0)
      this.yourLockUntil = undefined
      this.claimableEarnings = new Array(TOKEN_COUNT).fill(
        ethers.BigNumber.from(0)
      )
    },
    async fetchHasZCombLock() {
      try {
        this.hasLock = await veCOMBContract?.has_lock(this.wallet.address)
      } catch (err) {
        this.hasLock = false
        createToast({
          type: ToastStatus.Error,
          text: `Could not verify if you have a lock already: ${getReportedMessage(
            err
          )}`
        })
      }
    },
    async createVeCOMBLock(
      value: ethers.BigNumber,
      lockDate: DateTime
    ): Promise<void> {
      try {
        const tx = await veCOMBContract?.create_lock(
          value,
          Math.ceil(lockDate.toSeconds())
        )

        const waitingToast = createToast({
          type: ToastStatus.Info,
          text: 'Submitted lock creation, awaiting confirmation...',
          duration: 0
        })
        await tx.wait()
        waitingToast.close()

        createToast({
          type: ToastStatus.Success,
          text: 'Successfully locked your comb'
        })

        this.store.fetchCombBalance(true)
        this.fetchHasZCombLock()
        this.fetchUserLockedComb()
        this.fetchUserLockedUntil()
      } catch (err: any) {
        createToast({
          type: ToastStatus.Error,
          text: `Could not create lock: ${getReportedMessage(err)}`
        })
      }
    },
    async increaseLockAmount(value: ethers.BigNumber): Promise<void> {
      try {
        const tx = await veCOMBContract?.increase_amount(value)

        const waitingToast = createToast({
          type: ToastStatus.Info,
          text: 'Submitted increasing lock value, awaiting confirmation...',
          duration: 0
        })
        await tx.wait()
        waitingToast.close()

        createToast({
          type: ToastStatus.Success,
          text: 'Successfully increased the amount on your lock!'
        })

        this.store.fetchCombBalance(true)
        this.fetchUserLockedComb()
        this.fetchUserzCOMB()
      } catch (err: any) {
        createToast({
          type: ToastStatus.Error,
          text: `Could not increase amount on lock: ${getReportedMessage(err)}`
        })
      }
    },

    async increaseLockDate(lockDate: DateTime): Promise<void> {
      try {
        const tx = await veCOMBContract?.increase_unlock_time(
          lockDate.toSeconds() >
            DateTime.now().toSeconds() + SECONDS_PER_YEAR * 2
            ? Math.floor(DateTime.now().toSeconds() + SECONDS_PER_YEAR * 2)
            : Math.ceil(lockDate.toSeconds())
        )

        const waitingToast = createToast({
          type: ToastStatus.Info,
          text: 'Submitted increasing lock date, awaiting confirmation...',
          duration: 0
        })
        await tx.wait()
        waitingToast.close()

        createToast({
          type: ToastStatus.Success,
          text: 'Successfully increased the date on your lock!'
        })
        this.fetchUserLockedUntil()
      } catch (err: any) {
        createToast({
          type: ToastStatus.Error,
          text: `Could not increase date on lock: ${getReportedMessage(err)}`
        })
      }
    },
    async fetchLastClaimDate(): Promise<ethers.BigNumber | any> {
      try {
        this.lastClaimDate = await feeDistributorContract?.last_claimed_date(
          this.wallet.address
        )
      } catch (err: any) {
        createToast({
          type: ToastStatus.Error,
          text: `Could not get last date claimed: ${getReportedMessage(err)}`
        })
      }
    },
    async claimFeeDistributor(): Promise<void> {
      try {
        const MAX_DATE_TO_CLAIM = 51
        await this.fetchLastClaimDate()

        const tx =
          this.lastClaimDate > MAX_DATE_TO_CLAIM
            ? await feeDistributorContract?.claim_many([this.wallet.address])
            : await feeDistributorContract?.claim()

        const waitingToast = createToast({
          type: ToastStatus.Info,
          text: 'Submitted rewards claiming, awaiting confirmation...',
          duration: 0
        })
        await tx.wait()
        waitingToast.close()

        createToast({
          type: ToastStatus.Success,
          text: 'Successfully claimed your rewards!'
        })

        this.store.fetchCombBalance(true)
      } catch (err: any) {
        createToast({
          type: ToastStatus.Error,
          text: `Could not claim your rewards: ${getReportedMessage(err)}`
        })
      }
    },
    async withdrawVeComb(): Promise<void> {
      try {
        const tx = await veCOMBContract?.withdraw()

        const waitingToast = createToast({
          type: ToastStatus.Info,
          text: 'Submitted withdraw, awaiting confirmation...',
          duration: 0
        })
        await tx.wait()
        waitingToast.close()

        createToast({
          type: ToastStatus.Success,
          text: 'Successfully withdrew your zCOMB!'
        })

        this.fetchLastClaimDate()
        this.fetchUserLockedComb()
        this.fetchUserzCOMB()
      } catch (err: any) {
        createToast({
          type: ToastStatus.Error,
          text: `Could not withdraw your zCOMB: ${getReportedMessage(err)}`
        })
      }
    },
    async fetchUserLockedComb() {
      try {
        const userlockedCombArr = await veCOMBContract?.locked(
          this.wallet.address
        )
        this.yourLockedComb = userlockedCombArr[0]
      } catch (err: any) {
        this.yourLockedComb = ethers.BigNumber.from(0)
        createToast({
          type: ToastStatus.Error,
          text: `User locked COMB sync failed: ${getReportedMessage(err)}`
        })
      }
    },
    async fetchUserzCOMB() {
      try {
        this.yourzCOMB = await veCOMBContract?.balanceOf(this.wallet.address)
      } catch (err: any) {
        this.yourzCOMB = ethers.BigNumber.from(0)
        createToast({
          type: ToastStatus.Error,
          text: `User zCOMB sync failed: ${getReportedMessage(err)}`
        })
      }
    },
    async fetchUserLockedUntil() {
      try {
        const lockedUntil = await veCOMBContract?.locked__end(
          this.wallet.address
        )

        this.yourLockUntil = !lockedUntil.eq(0)
          ? DateTime.fromSeconds(lockedUntil.toNumber())
          : undefined
      } catch (err: any) {
        this.yourLockUntil = undefined
        createToast({
          type: ToastStatus.Error,
          text: `User zCOMB sync failed: ${getReportedMessage(err)}`
        })
      }
    },
    async fetchClaimableEarnings(): Promise<void> {
      try {
        const response = await feeDistributorContract?.callStatic.claim()
        this.claimableEarnings = [...response]
      } catch (err: any) {
        createToast({
          type: ToastStatus.Error,
          text: `Something went wrong during claimable earnings fetch: ${getReportedMessage(
            err
          )}`
        })
      }
    },
    async syncClaimableTokensUsdPrice(): Promise<void> {
      this.claimableTokensUsdPrice = await Promise.all(
        this.claimableTokensUsdPrice.map(async (token) => {
          const tokenPriceInUsd = await fetchPriceFromOracleEndpoint(
            retrieveAddressByIdentifier(token.addressBookIdentifier)
          )
          return {
            ...token,
            tokenPriceInUsd
          }
        })
      )
    },
    initTokenPriceSync(): void {
      if (!priceSyncInterval) {
        this.syncClaimableTokensUsdPrice()
        priceSyncInterval = setInterval(async () => {
          this.syncClaimableTokensUsdPrice()
        }, SYNC_TIME_IN_MILLISECONDS)
      }
    }
  }
})
