import { isOnMainnet } from '../environment'

export const NftContracts = {
  BearishMarketplace: {
    address: isOnMainnet
      ? '0x3A004cd8290848C8dC429929c61Bf515fe2EfdAD'
      : '0x0FA76dAe0CB1B104C71812B994d3CfD1DB727A85',
    abi: [
      'constructor()',
      'event ItemCanceled(address indexed,address indexed,uint256)',
      'event ItemListed(address indexed,address indexed,uint256,uint256,address,uint256,uint256)',
      'event ItemSold(address indexed,address indexed,address indexed,uint256,uint256,address,uint256)',
      'event ItemUpdated(address indexed,address indexed,uint256,address,uint256)',
      'event OfferCanceled(address indexed,address indexed,uint256)',
      'event OfferCreated(address indexed,address indexed,uint256,uint256,address,uint256,uint256)',
      'event RoleAdminChanged(bytes32 indexed,bytes32 indexed,bytes32 indexed)',
      'event RoleGranted(bytes32 indexed,address indexed,address indexed)',
      'event RoleRevoked(bytes32 indexed,address indexed,address indexed)',
      'event UpdatePlatformFee(uint256)',
      'function DEFAULT_ADMIN_ROLE() view returns (bytes32)',
      'function addressRegistry() view returns (address)',
      'function buyItem(address,uint256,address,address)',
      'function cancelListing(address,uint256)',
      'function collectionRoyalties(address) view returns (uint256, address, address)',
      'function getRoleAdmin(bytes32) view returns (bytes32)',
      'function grantRole(bytes32,address)',
      'function hasRole(bytes32,address) view returns (bool)',
      'function initialize(uint256)',
      'function listItem(address,uint256,uint256,address,uint256,uint256)',
      'function listings(address,uint256,address) view returns (uint256, address, uint256, uint256)',
      'function minters(address,uint256) view returns (address)',
      'function offers(address,uint256,address) view returns (address, uint256, uint256, uint256)',
      'function platformFee() view returns (uint256)',
      'function registerCollectionRoyalty(address,address,uint256,address)',
      'function registerRoyalty(address,uint256,uint256)',
      'function renounceRole(bytes32,address)',
      'function revokeRole(bytes32,address)',
      'function royalties(address,uint256) view returns (uint256)',
      'function supportsInterface(bytes4) view returns (bool)',
      'function updateAddressRegistry(address)',
      'function updateListing(address,uint256,address,uint256)',
      'function updatePlatformFee(uint256)'
    ]
  },
  BearishHives: {
    address: isOnMainnet
      ? '0xF0f8F779F2510eD7f6869dC811D6fBd84b1D1045'
      : '0xE3fe47d876Dd65122a297a36fCB6803fFEC818Fb',
    abi: [
      'constructor()',
      'event Approval(address indexed,address indexed,uint256 indexed)',
      'event ApprovalForAll(address indexed,address indexed,bool)',
      'event RoleAdminChanged(bytes32 indexed,bytes32 indexed,bytes32 indexed)',
      'event RoleGranted(bytes32 indexed,address indexed,address indexed)',
      'event RoleRevoked(bytes32 indexed,address indexed,address indexed)',
      'event Transfer(address indexed,address indexed,uint256 indexed)',
      'function DEFAULT_ADMIN_ROLE() view returns (bytes32)',
      'function MINTER_ROLE() view returns (bytes32)',
      'function PRECISION() view returns (uint256)',
      'function approve(address,uint256)',
      'function balanceOf(address) view returns (uint256)',
      'function burn(uint256)',
      'function defaultUri() view returns (string)',
      'function getApproved(uint256) view returns (address)',
      'function getRoleAdmin(bytes32) view returns (bytes32)',
      'function grantRole(bytes32,address)',
      'function hasRole(bytes32,address) view returns (bool)',
      'function hiveCounts(uint256) view returns (uint256)',
      'function hiveMultipliers(uint256) view returns (uint256)',
      'function hiveUris(uint256) view returns (string)',
      'function initialize()',
      'function isApprovedForAll(address,address) view returns (bool)',
      'function name() view returns (string)',
      'function ownerOf(uint256) view returns (address)',
      'function renounceRole(bytes32,address)',
      'function revokeRole(bytes32,address)',
      'function royaltyInfo(uint256,uint256) view returns (address, uint256)',
      'function safeMint(address,uint256) returns (uint256)',
      'function safeTransferFrom(address,address,uint256)',
      'function safeTransferFrom(address,address,uint256,bytes)',
      'function setApprovalForAll(address,bool)',
      'function setDefaultRoyalty(address,uint96)',
      'function supportsInterface(bytes4) view returns (bool)',
      'function symbol() view returns (string)',
      'function tokenByIndex(uint256) view returns (uint256)',
      'function tokenOfOwnerByIndex(address,uint256) view returns (uint256)',
      'function tokenURI(uint256) view returns (string)',
      'function totalSupply() view returns (uint256)',
      'function transferFrom(address,address,uint256)',
      'function updateDefaultUri(string)',
      'function updateUri(uint256,string)'
    ]
  },
  BearishHiveManager: {
    address: isOnMainnet
      ? '0x83fc737409BA45eB825DA881E3f54886E3a21d37'
      : '0x16d5EA9c84A834aDdC2A34cf773f712069CBa626',
    abi: [
      'constructor()',
      'event BearishHiveMinted(uint256,uint256,uint256)',
      'event DistributedRewards(uint256 indexed,uint256 indexed)',
      'event Initialized(uint8)',
      'event RoleAdminChanged(bytes32 indexed,bytes32 indexed,bytes32 indexed)',
      'event RoleGranted(bytes32 indexed,address indexed,address indexed)',
      'event RoleRevoked(bytes32 indexed,address indexed,address indexed)',
      'function CASHIER_ROLE() view returns (bytes32)',
      'function DEFAULT_ADMIN_ROLE() view returns (bytes32)',
      'function PRECISION() view returns (uint256)',
      'function bearishHives() view returns (address)',
      'function canDistribute() view returns (bool)',
      'function cashoutMany(uint256[]) returns (uint256[])',
      'function cashoutRewards(uint256) returns (uint256)',
      'function createHive(string,uint256) returns (uint256)',
      'function distributeRewards()',
      'function distributionDuration() view returns (uint256)',
      'function estimateRewardsTax(uint256[],uint256) view returns (uint256, uint256, uint256)',
      'function getRewardAmountOf(uint256) view returns (uint256)',
      'function getRewardAmountOfMany(uint256[]) view returns (uint256)',
      'function getRoleAdmin(bytes32) view returns (bytes32)',
      'function getStructuredTax() view returns (uint256[], uint256[], uint256)',
      'function grantRole(bytes32,address)',
      'function hasRole(bytes32,address) view returns (bool)',
      'function hasTokenExpired(uint256) view returns (bool)',
      'function hivesData(uint256) view returns (string, uint256, uint256, uint256)',
      'function initialize(address,address,uint256)',
      'function lastDistribution() view returns (uint256)',
      'function lastRewardIndex() view returns (uint256)',
      'function management() view returns (address)',
      'function migrateHive(uint256) returns (uint256)',
      'function migrateMany(uint256[]) returns (uint256[])',
      'function migrateManyAndCompound(uint256[]) returns (uint256[])',
      'function renounceRole(bytes32,address)',
      'function revokeRole(bytes32,address)',
      'function rewardIntegrals(uint256) view returns (uint256, uint256, uint256, bool)',
      'function setTokenCreationTime(uint256,uint256)',
      'function setTokenMultiplier(uint256,uint256)',
      'function structuredTax() view returns (uint256, uint256)',
      'function supportsInterface(bytes4) view returns (bool)',
      'function tokenExpirationDate(uint256) view returns (uint256)',
      'function updateCanDistribute(bool)',
      'function updateDistributionDuration(uint256)',
      'function updateRewards(uint256)',
      'function updateStructuredTax(uint256[],uint256[],uint256)'
    ]
  },
  BearishHivesBeacon: {
    address: isOnMainnet
      ? '0xb82124C0a4Eb85D6f932454AfA292474BBdCf05F'
      : '0xfc2C2b6B5CA71d9f8A2C1c7C4B3F222B8BEdC96D',
    abi: [
      'constructor()',
      'event RoleAdminChanged(bytes32 indexed,bytes32 indexed,bytes32 indexed)',
      'event RoleGranted(bytes32 indexed,address indexed,address indexed)',
      'event RoleRevoked(bytes32 indexed,address indexed,address indexed)',
      'function DEFAULT_ADMIN_ROLE() view returns (bytes32)',
      'function PRECISION() view returns (uint256)',
      'function SPIRIT_ROUTER() view returns (address)',
      'function WFTM() view returns (address)',
      'function bearishHives() view returns (address)',
      'function bearishHivesManager() view returns (address)',
      'function claimMany(uint256[]) returns (uint256)',
      'function claimManyExpired(uint256[]) returns (uint256)',
      'function expiryFee() view returns (uint256)',
      'function getRoleAdmin(bytes32) view returns (bytes32)',
      'function grantRole(bytes32,address)',
      'function hasRole(bytes32,address) view returns (bool)',
      'function initialize(address,address,address,address,address,address)',
      'function protocol() view returns (address)',
      'function protocolFee() view returns (uint256)',
      'function renounceRole(bytes32,address)',
      'function revokeRole(bytes32,address)',
      'function reward() view returns (address)',
      'function rewardToWftm(uint256) view returns (address)',
      'function rewardsPool() view returns (address)',
      'function supportsInterface(bytes4) view returns (bool)',
      'function treasury() view returns (address)',
      'function updateProtocol(address)',
      'function updateProtocolFee(uint256)',
      'function updateTreasury(address)'
    ]
  },
  BearishBoostedBeacon: {
    address: isOnMainnet
      ? '0xF5bF2F606090EA96196d1535927ADD33ea3eDbc1'
      : '0xEc9693B5A90e42486C2F6ee4AEB474e94b60e18e',
    abi: [
      'event ClaimAndCreatedLock(address indexed,uint256)',
      'event ClaimAndLock(address indexed,uint256)',
      'event Initialized(uint8)',
      'event Paused(address)',
      'event RoleAdminChanged(bytes32 indexed,bytes32 indexed,bytes32 indexed)',
      'event RoleGranted(bytes32 indexed,address indexed,address indexed)',
      'event RoleRevoked(bytes32 indexed,address indexed,address indexed)',
      'event Unpaused(address)',
      'function DEFAULT_ADMIN_ROLE() view returns (bytes32)',
      'function PRECISION() view returns (uint256)',
      'function bearishHives() view returns (address)',
      'function booster() view returns (address)',
      'function claimAndCreateLock(uint256[]) returns (uint256)',
      'function claimAndFundLock(uint256[]) returns (uint256)',
      'function claimAndFundLockTo(uint256[],address) returns (uint256)',
      'function distributionPool() view returns (address)',
      'function getRoleAdmin(bytes32) view returns (bytes32)',
      'function grantRole(bytes32,address)',
      'function hasRole(bytes32,address) view returns (bool)',
      'function initialize(address,address,address,address,address,address)',
      'function management() view returns (address)',
      'function pause()',
      'function paused() view returns (bool)',
      'function protocol() view returns (address)',
      'function protocolFee() view returns (uint256)',
      'function renounceRole(bytes32,address)',
      'function revokeRole(bytes32,address)',
      'function setBooster(address)',
      'function setDistributionPool(address)',
      'function setManagement(address)',
      'function setVeCombToken(address)',
      'function supportsInterface(bytes4) view returns (bool)',
      'function underlyingToken() view returns (address)',
      'function unpause()',
      'function veCOMB() view returns (address)'
    ]
  },
  CombExclusive: {
    address: '0x3f609cd4999e35d1b6fa4a7d3a883252ec644457',
    abi: [
      'event ApprovalForAll(address indexed,address indexed,bool)',
      'event TransferBatch(address indexed,address indexed,address indexed,uint256[],uint256[])',
      'event TransferSingle(address indexed,address indexed,address indexed,uint256,uint256)',
      'event URI(string,uint256 indexed)',
      'function balanceOf(address,uint256) view returns (uint256)',
      'function balanceOfBatch(address[],uint256[]) view returns (uint256[])',
      'function isApprovedForAll(address,address) view returns (bool)',
      'function safeBatchTransferFrom(address,address,uint256[],uint256[],bytes)',
      'function safeTransferFrom(address,address,uint256,uint256,bytes)',
      'function setApprovalForAll(address,bool)',
      'function supportsInterface(bytes4) view returns (bool)'
    ]
  }
}

export default NftContracts
