export const VaultContracts = {
  Vault: {
    ABI: [
      'event Approval(address indexed owner, address indexed spender, uint256 value)',
      'event DepositsIncremented(address user, uint256 amount, uint256 total)',
      'event NewStratCandidate(address implementation)',
      'event OwnershipTransferred(address indexed previousOwner, address indexed newOwner)',
      'event TermsAccepted(address user)',
      'event Transfer(address indexed from, address indexed to, uint256 value)',
      'event UpgradeStrat(address implementation)',
      'event WithdrawalsIncremented(address user, uint256 amount, uint256 total)',
      'function allowance(address owner, address spender) view returns (uint256)',
      'function approvalDelay() view returns (uint256)',
      'function approve(address spender, uint256 amount) returns (bool)',
      'function available() view returns (uint256)',
      'function balance() view returns (uint256)',
      'function balanceOf(address account) view returns (uint256)',
      'function constructionTime() view returns (uint256)',
      'function cumulativeDeposits(address) view returns (uint256)',
      'function cumulativeWithdrawals(address) view returns (uint256)',
      'function decimals() view returns (uint8)',
      'function decreaseAllowance(address spender, uint256 subtractedValue) returns (bool)',
      'function deposit(uint256 _amount)',
      'function depositAll()',
      'function earn()',
      'function getPricePerFullShare() view returns (uint256)',
      'function exchangeRate() view returns (uint256)',
      'function inCaseTokensGetStuck(address _token)',
      'function increaseAllowance(address spender, uint256 addedValue) returns (bool)',
      'function initialize(address _token, string _name, string _symbol, uint256 _approvalDelay)',
      'function initializeStrategy(address _strategy) returns (bool)',
      'function initialized() view returns (bool)',
      'function name() view returns (string)',
      'function owner() view returns (address)',
      'function proposeStrat(address _implementation)',
      'function renounceOwnership()',
      'function stratCandidate() view returns (address implementation, uint256 proposedTime)',
      'function strategy() view returns (address)',
      'function symbol() view returns (string)',
      'function token() view returns (address)',
      'function totalSupply() view returns (uint256)',
      'function transfer(address recipient, uint256 amount) returns (bool)',
      'function transferFrom(address sender, address recipient, uint256 amount) returns (bool)',
      'function transferOwnership(address newOwner)',
      'function upgradeStrat()',
      'function withdraw(uint256 _shares)',
      'function withdrawAll()'
    ]
  },
  TShareRewardPool: {
    ABI: [
      'constructor(address _tshare, uint256 _poolStartTime)',
      'event Deposit(address indexed user, uint256 indexed pid, uint256 amount)',
      'event EmergencyWithdraw(address indexed user, uint256 indexed pid, uint256 amount)',
      'event RewardPaid(address indexed user, uint256 amount)',
      'event Withdraw(address indexed user, uint256 indexed pid, uint256 amount)',
      'function TOTAL_REWARDS() view returns (uint256)',
      'function add(uint256 _allocPoint, address _token, bool _withUpdate, uint256 _lastRewardTime)',
      'function deposit(uint256 _pid, uint256 _amount)',
      'function emergencyWithdraw(uint256 _pid)',
      'function getGeneratedReward(uint256 _fromTime, uint256 _toTime) view returns (uint256)',
      'function governanceRecoverUnsupported(address _token, uint256 amount, address to)',
      'function massUpdatePools()',
      'function operator() view returns (address)',
      'function pendingShare(uint256 _pid, address _user) view returns (uint256)',
      'function poolEndTime() view returns (uint256)',
      'function poolInfo(uint256) view returns (address token, uint256 allocPoint, uint256 lastRewardTime, uint256 accTSharePerShare, bool isStarted)',
      'function poolStartTime() view returns (uint256)',
      'function runningTime() view returns (uint256)',
      'function set(uint256 _pid, uint256 _allocPoint)',
      'function setOperator(address _operator)',
      'function tSharePerSecond() view returns (uint256)',
      'function totalAllocPoint() view returns (uint256)',
      'function tshare() view returns (address)',
      'function updatePool(uint256 _pid)',
      'function userInfo(uint256, address) view returns (uint256 amount, uint256 rewardDebt)',
      'function withdraw(uint256 _pid, uint256 _amount)'
    ]
  },
  LpDepositor: {
    ABI: [
      'constructor(address _solid, address _votingEscrow, address _solidlyVoter)',
      'event Deposited(address indexed user, address indexed pool, uint256 amount)',
      'event OwnershipTransferred(address indexed previousOwner, address indexed newOwner)',
      'event RewardAdded(address indexed rewardsToken, uint256 reward)',
      'event RewardPaid(address indexed user, address indexed rewardsToken, uint256 reward)',
      'event TransferDeposit(address indexed pool, address indexed from, address indexed to, uint256 amount)',
      'event Withdrawn(address indexed user, address indexed pool, uint256 amount)',
      'function SEX() view returns (address)',
      'function SOLID() view returns (address)',
      'function SOLIDsex() view returns (address)',
      'function bribeForPool(address) view returns (address)',
      'function claimLockerRewards(address pool, address[] gaugeRewards, address[] bribeRewards)',
      'function deposit(address pool, uint256 amount)',
      'function depositTokenImplementation() view returns (address)',
      'function feeDistributor() view returns (address)',
      'function gaugeForPool(address) view returns (address)',
      'function getReward(address[] pools)',
      'function onERC721Received(address _operator, address _from, uint256 _tokenID, bytes) returns (bytes4)',
      'function owner() view returns (address)',
      'function pendingRewards(address account, address[] pools) view returns (tuple(uint256 solid, uint256 sex)[] pending)',
      'function renounceOwnership()',
      'function rewardIntegral(address) view returns (uint256 solid, uint256 sex)',
      'function rewardIntegralFor(address, address) view returns (uint256 solid, uint256 sex)',
      'function setAddresses(address _sex, address _solidsex, address _solidexVoter, address _feeDistributor, address _stakingRewards, address _tokenWhitelister, address _depositToken)',
      'function solidlyVoter() view returns (address)',
      'function stakingRewards() view returns (address)',
      'function tokenForPool(address) view returns (address)',
      'function tokenID() view returns (uint256)',
      'function tokenWhitelister() view returns (address)',
      'function totalBalances(address) view returns (uint256)',
      'function transferDeposit(address pool, address from, address to, uint256 amount) returns (bool)',
      'function transferOwnership(address newOwner)',
      'function userBalances(address, address) view returns (uint256)',
      'function votingEscrow() view returns (address)',
      'function whitelist(address token) returns (bool)',
      'function whitelistProtocolTokens()',
      'function withdraw(address pool, uint256 amount)'
    ]
  },
  Gauge: {
    ABI: [
      'constructor(address _stake, address _bribe, address __ve, address _voter)',
      'event ClaimFees(address indexed from, uint256 claimed0, uint256 claimed1)',
      'event ClaimRewards(address indexed from, address indexed reward, uint256 amount)',
      'event Deposit(address indexed from, uint256 tokenId, uint256 amount)',
      'event NotifyReward(address indexed from, address indexed reward, uint256 amount)',
      'event Withdraw(address indexed from, uint256 tokenId, uint256 amount)',
      'function _ve() view returns (address)',
      'function balanceOf(address) view returns (uint256)',
      'function batchRewardPerToken(address token, uint256 maxRuns)',
      'function bribe() view returns (address)',
      'function checkpoints(address, uint256) view returns (uint256 timestamp, uint256 balanceOf)',
      'function claimFees() returns (uint256 claimed0, uint256 claimed1)',
      'function deposit(uint256 amount, uint256 tokenId)',
      'function depositAll(uint256 tokenId)',
      'function derivedBalance(address account) view returns (uint256)',
      'function derivedBalances(address) view returns (uint256)',
      'function derivedSupply() view returns (uint256)',
      'function earned(address token, address account) view returns (uint256)',
      'function fees0() view returns (uint256)',
      'function fees1() view returns (uint256)',
      'function getPriorBalanceIndex(address account, uint256 timestamp) view returns (uint256)',
      'function getPriorRewardPerToken(address token, uint256 timestamp) view returns (uint256, uint256)',
      'function getPriorSupplyIndex(uint256 timestamp) view returns (uint256)',
      'function getReward(address account, address[] tokens)',
      'function isReward(address) view returns (bool)',
      'function lastEarn(address, address) view returns (uint256)',
      'function lastTimeRewardApplicable(address token) view returns (uint256)',
      'function lastUpdateTime(address) view returns (uint256)',
      'function left(address token) view returns (uint256)',
      'function notifyRewardAmount(address token, uint256 amount)',
      'function numCheckpoints(address) view returns (uint256)',
      'function periodFinish(address) view returns (uint256)',
      'function rewardPerToken(address token) view returns (uint256)',
      'function rewardPerTokenCheckpoints(address, uint256) view returns (uint256 timestamp, uint256 rewardPerToken)',
      'function rewardPerTokenNumCheckpoints(address) view returns (uint256)',
      'function rewardPerTokenStored(address) view returns (uint256)',
      'function rewardRate(address) view returns (uint256)',
      'function rewards(uint256) view returns (address)',
      'function rewardsListLength() view returns (uint256)',
      'function stake() view returns (address)',
      'function supplyCheckpoints(uint256) view returns (uint256 timestamp, uint256 supply)',
      'function supplyNumCheckpoints() view returns (uint256)',
      'function tokenIds(address) view returns (uint256)',
      'function totalSupply() view returns (uint256)',
      'function userRewardPerTokenStored(address, address) view returns (uint256)',
      'function voter() view returns (address)',
      'function withdraw(uint256 amount)',
      'function withdrawAll()',
      'function withdrawToken(uint256 amount, uint256 tokenId)'
    ]
  },
  SpiritGauge: {
    ABI: [
      'constructor(address _stake, address _bribe, address __ve, address _voter)',
      'event ClaimFees(address indexed from, uint256 claimed0, uint256 claimed1)',
      'event ClaimRewards(address indexed from, address indexed reward, uint256 amount)',
      'event Deposit(address indexed from, uint256 tokenId, uint256 amount)',
      'event NotifyReward(address indexed from, address indexed reward, uint256 amount)',
      'event Withdraw(address indexed from, uint256 tokenId, uint256 amount)',
      'function _ve() view returns (address)',
      'function balanceOf(address) view returns (uint256)',
      'function batchRewardPerToken(address token, uint256 maxRuns)',
      'function bribe() view returns (address)',
      'function checkpoints(address, uint256) view returns (uint256 timestamp, uint256 balanceOf)',
      'function claimFees() returns (uint256 claimed0, uint256 claimed1)',
      'function deposit(uint256 amount, uint256 tokenId)',
      'function depositAll(uint256 tokenId)',
      'function derivedBalance(address account) view returns (uint256)',
      'function derivedBalances(address) view returns (uint256)',
      'function derivedSupply() view returns (uint256)',
      'function earned(address token, address account) view returns (uint256)',
      'function fees0() view returns (uint256)',
      'function fees1() view returns (uint256)',
      'function getPriorBalanceIndex(address account, uint256 timestamp) view returns (uint256)',
      'function getPriorRewardPerToken(address token, uint256 timestamp) view returns (uint256, uint256)',
      'function getPriorSupplyIndex(uint256 timestamp) view returns (uint256)',
      'function getReward(address account, address[] tokens)',
      'function isReward(address) view returns (bool)',
      'function lastEarn(address, address) view returns (uint256)',
      'function lastTimeRewardApplicable(address token) view returns (uint256)',
      'function lastUpdateTime(address) view returns (uint256)',
      'function left(address token) view returns (uint256)',
      'function notifyRewardAmount(address token, uint256 amount)',
      'function numCheckpoints(address) view returns (uint256)',
      'function periodFinish(address) view returns (uint256)',
      'function rewardPerToken(address token) view returns (uint256)',
      'function rewardPerTokenCheckpoints(address, uint256) view returns (uint256 timestamp, uint256 rewardPerToken)',
      'function rewardPerTokenNumCheckpoints(address) view returns (uint256)',
      'function rewardPerTokenStored(address) view returns (uint256)',
      'function rewardRate() view returns (uint256)',
      'function rewards(uint256) view returns (address)',
      'function rewardsListLength() view returns (uint256)',
      'function stake() view returns (address)',
      'function supplyCheckpoints(uint256) view returns (uint256 timestamp, uint256 supply)',
      'function supplyNumCheckpoints() view returns (uint256)',
      'function tokenIds(address) view returns (uint256)',
      'function totalSupply() view returns (uint256)',
      'function userRewardPerTokenStored(address, address) view returns (uint256)',
      'function voter() view returns (address)',
      'function withdraw(uint256 amount)',
      'function withdrawAll()',
      'function withdrawToken(uint256 amount, uint256 tokenId)'
    ]
  },
  MiniChef: {
    ABI: [
      'function poolInfo(uint256 pid) view returns (uint256 allocPoint, uint256 lastRewardBlock, uint256 accBeetsPerShare)',
      'function beetsPerBlock() view returns (uint256)',
      'function totalAllocPoint() view returns (uint256)'
    ]
  },
  BooChef: {
    ABI: [
      'function booPerSecond() view returns (uint256)',
      'function poolInfo(uint256 pid) view returns (address lpToken, uint256 allocPoint, uint256 lastRewardTime, uint256 accBOOPerShare)',
      'function totalAllocPoint() view returns (uint256)'
    ]
  }
}

export default VaultContracts
