import CombContracts from './CombContracts'
import DependencyContracts from './DependencyContracts'
import NftContracts from './NftContracts'
import PodsContracts from './PodsContracts'
import TokenContracts from './TokenContracts'
import VaultContracts from './VaultContracts'
import WarContracts from './WarContracts'
import ZapContracts from './ZapContracts'

const SmartContracts = {
  ...CombContracts,
  ...DependencyContracts,
  ...VaultContracts,
  ...ZapContracts,
  ...WarContracts,
  ...PodsContracts,
  ...NftContracts,
  ...TokenContracts
}

export default SmartContracts
