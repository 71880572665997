import { defineStore } from 'pinia'

import { emptyResponseWithStatus, FETCH_STATUS } from '../api/constants'
import {
  CombApiState,
  PoolsApiResponse,
  VaultsApiResponse,
  ZCombApiResponse
} from '.'
import { fetchCombApi } from './actions'

export const useCombApi = defineStore('comb-api', {
  state(): CombApiState {
    return {
      zcomb: emptyResponseWithStatus<ZCombApiResponse>(),
      vaults: emptyResponseWithStatus<VaultsApiResponse>(),
      pools: emptyResponseWithStatus<PoolsApiResponse>()
    }
  },
  getters: {},
  actions: {
    async refreshData(): Promise<void> {
      await this.fetchZCombApi()
      await this.fetchVaultsApi()
      await this.fetchPoolsApi()
    },
    async fetchZCombApi(): Promise<void> {
      try {
        this.$state.zcomb.status = FETCH_STATUS.LOADING

        this.$state.zcomb.response = await fetchCombApi<ZCombApiResponse>(
          'zcomb'
        )
        this.$state.zcomb.status = FETCH_STATUS.OK
      } catch (error) {
        console.error(error)
      }
    },
    async fetchVaultsApi(): Promise<void> {
      try {
        this.$state.zcomb.status = FETCH_STATUS.LOADING
        this.$state.vaults.response = await fetchCombApi<VaultsApiResponse>(
          'vaults'
        )
        this.$state.zcomb.status = FETCH_STATUS.OK
      } catch (error) {
        console.error(error)
        this.$state.zcomb.status = FETCH_STATUS.ERROR
      }
    },
    async fetchPoolsApi(): Promise<void> {
      try {
        this.$state.zcomb.status = FETCH_STATUS.LOADING
        this.$state.pools.response = await fetchCombApi<PoolsApiResponse>(
          'pools'
        )
        this.$state.zcomb.status = FETCH_STATUS.OK
      } catch (error) {
        console.error(error)
        this.$state.zcomb.status = FETCH_STATUS.ERROR
      }
    }
  }
})
