import { isOnMainnet } from '../environment'

export interface claimEarningsType {
  id: string
  addressBookIdentifier: string
  tokenPriceInUsd: number
}

export const claimEarnings = isOnMainnet
  ? [
      {
        id: 'COMB',
        addressBookIdentifier: 'comb-finance',
        tokenPriceInUsd: 0
      },
      {
        id: 'WFTM',
        addressBookIdentifier: 'fantom',
        tokenPriceInUsd: 0
      },
      {
        id: 'BEETS',
        addressBookIdentifier: 'beethoven-x',
        tokenPriceInUsd: 0
      },
      {
        id: 'BOO',
        addressBookIdentifier: 'spookyswap',
        tokenPriceInUsd: 0
      },
      {
        id: 'SPIRIT',
        addressBookIdentifier: 'spiritswap',
        tokenPriceInUsd: 0
      },
      {
        id: 'SCREAM',
        addressBookIdentifier: 'scream',
        tokenPriceInUsd: 0
      }
    ]
  : [
      {
        id: 'COMB',
        addressBookIdentifier: 'comb-finance',
        tokenPriceInUsd: 0
      },
      {
        id: 'WFTM',
        addressBookIdentifier: 'fantom',
        tokenPriceInUsd: 0
      }
    ]
