import { TransactionResponse } from '@ethersproject/abstract-provider'
import BigNumberJs from 'bignumber.js'
import { ethers } from 'ethers'
import { defineStore } from 'pinia'

import { signer, useWalletStore } from '@/store/wallet'
import {
  convertBigNumberToBigNumberJs,
  fetchTokenBalance,
  makeCallWithPendingToast
} from '@/utils/blockchain'
import SmartContracts from '@/utils/SmartContracts/smartContracts'
import { getTokenInfo, getTokenSmartContract } from '@/utils/token/tokenCache'

export interface BalancesState {
  approvedAmounts: { [token: string]: { [spender: string]: ethers.BigNumber } }
  balances: { [token: string]: ethers.BigNumber }
}

export const useBalanceStore = defineStore('balances', {
  state: (): BalancesState => {
    return {
      approvedAmounts: {},
      balances: {
        [SmartContracts.COMB.address]: ethers.constants.Zero,
        [SmartContracts.BOO.address]: ethers.constants.Zero,
        [SmartContracts.SPIRIT.address]: ethers.constants.Zero,
        [SmartContracts.SCREAM.address]: ethers.constants.Zero,
        [SmartContracts.BEETS.address]: ethers.constants.Zero
      }
    }
  },
  getters: {
    wallet() {
      return useWalletStore()
    },
    // Helpers
    hasKey: (state) => (token: string, spender: string) =>
      token in state.approvedAmounts && state.approvedAmounts[token][spender],
    // Getters
    allowance:
      (state) =>
      (token: string, spender: string): BigNumberJs =>
        token in state.approvedAmounts && state.approvedAmounts[token][spender]
          ? convertBigNumberToBigNumberJs(state.approvedAmounts[token][spender])
          : new BigNumberJs(0),
    balance:
      (state) =>
      (token: string): BigNumberJs =>
        token in state.balances
          ? convertBigNumberToBigNumberJs(state.balances[token])
          : new BigNumberJs(0),
    hasAllowance:
      (state) =>
      (token: string, spender: string, amount?: ethers.BigNumber): boolean =>
        token in state.approvedAmounts &&
        state.approvedAmounts[token][spender] &&
        state.approvedAmounts[token][spender].gt(amount ?? 0),
    approveTokenTx:
      () =>
      (
        tokenAddress: string,
        spenderAddress: string,
        amount?: ethers.BigNumber
      ): Promise<TransactionResponse> => {
        const tokenSmartContract = getTokenSmartContract(tokenAddress)?.connect(
          signer!
        )

        if (!amount || amount.eq(0)) {
          amount = ethers.constants.MaxUint256
        }

        return tokenSmartContract?.approve(spenderAddress, amount)
      }
  },
  actions: {
    init() {
      this.fetchBalance(SmartContracts.COMB.address)
    },
    reset() {
      Object.keys(this.balances).forEach((token) => {
        this.balances[token] = ethers.constants.Zero
      })
      Object.keys(this.approvedAmounts).forEach((token) => {
        Object.keys(token).forEach((spender) => {
          this.approvedAmounts[token][spender] = ethers.constants.Zero
        })
      })
    },
    async fetchAllowance(token: string, spender: string) {
      let allowance = ethers.constants.Zero
      const tokenContract = getTokenSmartContract(token)

      if (this.wallet.address && tokenContract) {
        allowance = await tokenContract.allowance(this.wallet.address, spender)
      }

      if (!this.approvedAmounts[token]) this.approvedAmounts[token] = {}
      this.approvedAmounts[token][spender] = allowance
    },
    async fetchBalance(token: string) {
      this.balances[token] = await fetchTokenBalance(
        token,
        this.balances[token]
      )
    },
    async approveToken(
      tokenAddress: string,
      spenderAddress: string,
      amount?: ethers.BigNumber
    ): Promise<void> {
      if (!signer) {
        return
      }

      const tokenInfo = await getTokenInfo(tokenAddress)
      if (!tokenInfo) {
        throw new Error("Can't fetch token contract")
      }

      return new Promise((resolve, reject) => {
        makeCallWithPendingToast({
          callTx: async () => {
            const tokenSmartContract = getTokenSmartContract(
              tokenAddress
            )?.connect(signer!)

            if (!amount || amount.eq(0)) {
              amount = ethers.constants.MaxUint256
            }
            return tokenSmartContract?.approve(spenderAddress, amount)
          },
          waitingToastText: `Approval for ${tokenInfo.symbol} sent. Awaiting confirmation...`,
          successToastText: `${tokenInfo.symbol} was successfully approved.`,
          onSuccess: async () => {
            await this.fetchAllowance(tokenAddress, spenderAddress)
            resolve()
          },
          onError: (err) => reject(err)
        })
      })
    }
  }
})
