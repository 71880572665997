<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <g>
        <path
          d="M373.333,117.333c-76.578,0-138.667,62.081-138.667,138.667c0,53.015-42.979,96-96,96c-53.007,0-96-42.993-96-96
			c0-53.021,42.985-96,96-96c13.445,0,26.452,2.74,38.454,7.985c10.796,4.718,23.373-0.209,28.091-11.005
			c4.718-10.796-0.209-23.373-11.005-28.091c-17.369-7.591-36.192-11.556-55.541-11.556C62.088,117.333,0,179.414,0,256
			c0,76.571,62.095,138.667,138.667,138.667c76.586,0,138.667-62.089,138.667-138.667c0-53.021,42.985-96,96-96
			c53.029,0,96,42.971,96,96c0,53.015-42.979,96-96,96c-13.416,0-26.421-2.749-38.422-8.002
			c-10.794-4.724-23.373,0.196-28.097,10.99c-4.724,10.794,0.196,23.373,10.99,28.097c17.369,7.602,36.195,11.582,55.53,11.582
			C449.919,394.667,512,332.578,512,256C512,179.407,449.926,117.333,373.333,117.333z"
        />
      </g>
    </g>
  </svg>
</template>
