import { isOnMainnet } from '../environment'

export const Contracts = {
  SpookySwapRouter: {
    address: '0xf491e7b69e4244ad4002bc14e878a34207e38c29',
    abi: [
      'function getAmountsOut(uint256 amountIn, address[] path) view returns (uint256[] amounts)'
    ]
  },
  BearishPods: {
    address: isOnMainnet
      ? '0xbac40dc5168d2f109dfad164af438d6b9c078254'
      : '0xFC10f96b8543Bf3eddA6E2E974A653bcDa20ebc1',
    abi: [
      'constructor()',
      'event Approval(address indexed,address indexed,uint256 indexed)',
      'event ApprovalForAll(address indexed,address indexed,bool)',
      'event RoleAdminChanged(bytes32 indexed,bytes32 indexed,bytes32 indexed)',
      'event RoleGranted(bytes32 indexed,address indexed,address indexed)',
      'event RoleRevoked(bytes32 indexed,address indexed,address indexed)',
      'event Transfer(address indexed,address indexed,uint256 indexed)',
      'function DEFAULT_ADMIN_ROLE() view returns (bytes32)',
      'function MINTER_ROLE() view returns (bytes32)',
      'function approve(address,uint256)',
      'function balanceOf(address) view returns (uint256)',
      'function getApproved(uint256) view returns (address)',
      'function getRoleAdmin(bytes32) view returns (bytes32)',
      'function grantRole(bytes32,address)',
      'function hasRole(bytes32,address) view returns (bool)',
      'function initialize()',
      'function isApprovedForAll(address,address) view returns (bool)',
      'function name() view returns (string)',
      'function ownerOf(uint256) view returns (address)',
      'function podCounts(uint8) view returns (uint256)',
      'function podTypes(uint256) view returns (uint8)',
      'function podUris(uint8) view returns (string)',
      'function renounceRole(bytes32,address)',
      'function revokeRole(bytes32,address)',
      'function safeMint(address,uint8) returns (uint256)',
      'function safeTransferFrom(address,address,uint256)',
      'function safeTransferFrom(address,address,uint256,bytes)',
      'function setApprovalForAll(address,bool)',
      'function supportsInterface(bytes4) view returns (bool)',
      'function symbol() view returns (string)',
      'function tokenURI(uint256) view returns (string)',
      'function totalSupply() view returns (uint256)',
      'function transferFrom(address,address,uint256)',
      'function updateUri(uint8,string)'
    ]
  },
  PodsManager: {
    address: isOnMainnet
      ? '0x0CDcbBf9Ae0341D904268F0a1373A67CE114c2B3'
      : '0x8CEa1871D36eCb7F37b8D138400D580f0eE5CBb7',
    abi: [
      'constructor()',
      'event Initialized(uint8)',
      'event MintedPod(uint256,uint8)',
      'event ModifiedBearishPods(address)',
      'event ModifiedMintingWhitelist(address indexed,bool indexed)',
      'event ModifiedSettings(address)',
      'event ModifiedWhitelister(address)',
      'event OwnershipTransferred(address indexed,address indexed)',
      'function PRECISION() view returns (uint256)',
      'function beacon() view returns (address)',
      'function bearishPods() view returns (address)',
      'function initialize(address,address,address,address)',
      'function isWhitelisting() view returns (bool)',
      'function mintFlexiblePod(uint256,uint256,uint256) payable',
      'function mintPod(uint256)',
      'function mintablePods(uint256) view returns (uint8, uint256, uint256, bool, address)',
      'function mintingWhitelist(address) view returns (bool)',
      'function owner() view returns (address)',
      'function pushMintablePod(tuple(uint8,uint256,uint256,bool,address))',
      'function renounceOwnership()',
      'function settings() view returns (address)',
      'function toggleWhitelist()',
      'function transferOwnership(address)',
      'function underlying() view returns (address)',
      'function updateBearishPods(address)',
      'function updateMintablePod(uint256,tuple(uint8,uint256,uint256,bool,address))',
      'function updateMintingWhitelist(address[],bool)',
      'function updatePodDepositor(uint256)',
      'function updateSettings(address)',
      'function updateWhitelister(address)',
      'function upgradeBeaconTo(address)',
      'function viewRoundPrice(uint256) view returns (uint256)',
      'function wallets(uint256) view returns (uint8, address, uint256, address)',
      'function whitelister() view returns (address)'
    ]
  },
  BeetsEditor: {
    address: '0xf2c262a88093B238f1aCdb5C96170B12B8e97cde',
    abi: [
      'function deposit(uint256)',
      'function shareBalances(address) view returns (uint256)'
    ]
  },
  PodsHelper: {
    address: isOnMainnet
      ? '0x9fF0436D1F44a965A7E9a5Ed2d5C2AEFE93f6AdF'
      : '0xe9453Af3cd985A2de67E7c6641BA1a139CF43CD3',
    abi: [
      'constructor()',
      'event ClaimedManyTokens(uint256,uint256[])',
      'event ClaimedToken(uint256,uint256)',
      'event Initialized(uint8)',
      'event ModifiedDisableWithdraw(uint256,bool)',
      'event ModifiedWhitelistedExecutive(uint8 indexed,address indexed,bool indexed)',
      'event RoleAdminChanged(bytes32 indexed,bytes32 indexed,bytes32 indexed)',
      'event RoleGranted(bytes32 indexed,address indexed,address indexed)',
      'event RoleRevoked(bytes32 indexed,address indexed,address indexed)',
      'function DEFAULT_ADMIN_ROLE() view returns (bytes32)',
      'function EDITOR_ROLE() view returns (bytes32)',
      'function bearishPods() view returns (address)',
      'function claimManyPods(uint256[],address) returns (uint256)',
      'function claimPod(uint256,address,address) returns (uint256)',
      'function claimPod(uint256,address) returns (uint256)',
      'function disableWithdrawDepositorAsset(uint256) view returns (bool)',
      'function escapePod(uint256)',
      'function feedAll(uint256[])',
      'function fundAndFeed(uint256,uint256)',
      'function getRoleAdmin(bytes32) view returns (bytes32)',
      'function grantRole(bytes32,address)',
      'function hasRole(bytes32,address) view returns (bool)',
      'function initialize(address,address)',
      'function manager() view returns (address)',
      'function migrateAll(uint256[])',
      'function renounceRole(bytes32,address)',
      'function revokeRole(bytes32,address)',
      'function skipComb(uint8) view returns (bool)',
      'function supportsInterface(bytes4) view returns (bool)',
      'function updateBearishPods(address)',
      'function updateDisableWithdrawDepositorAsset(uint256,bool)',
      'function updateSkipComb(uint8,bool)',
      'function updateWhitelistedExecutives(uint8,address,bool)',
      'function whitelistedExecutives(uint8,address) view returns (bool)',
      'function withdrawDepositorAsset(uint256,uint256)'
    ]
  },
  FtmPodLiquidator: {
    address: '0x268B774E692c814B6Fb1B6FD3A4B629F340937A2'
  },
  BtcPodLiquidator: { address: '0x551E1c2Cb994D5aDDCe1DF7048a10225aB66B868' },
  EthPodLiquidator: { address: '0x71E75E33c5431364AAC16b75d8f08e3FDd8EB54d' },
  AvaxPodLiquidator: { address: '0x6A9808479d327F5879467fD30d84f8f17e1bF43F' },
  CombPodLiquidator: {
    address: '0x0230E3001173C3567CB5a87bc24E57632D6eBa53'
  },
  TeamCombPodLiquidator: {
    address: '0x90B5FBC8832c5D3672b53188259d38698Ff266be'
  },
  ZCombPodLiquidator: {
    address: '0x71c22fac22d18353A9AaE279Ce34dde84Cf653F2'
  },
  PodWallet: {
    abi: [
      'constructor()',
      'event OwnershipTransferred(address indexed,address indexed)',
      'function claim(address)',
      'function createLock(uint256,uint256)',
      'function createMaxLock()',
      'function emergencyWithdraw(uint256,address)',
      'function feed(uint256,address) returns (uint256)',
      'function harvest(uint256,address) returns (uint256)',
      'function inCaseEthGetsStuck()',
      'function inCaseTokensGetStuck(address)',
      'function increaseLockAmount(uint256)',
      'function increaseLockTime(uint256)',
      'function initialize(address,address)',
      'function maxLockAmount()',
      'function maxLockTime()',
      'function owner() view returns (address)',
      'function pipe(address,uint256)',
      'function pipeTo(address,address,uint256,bool)',
      'function renounceOwnership()',
      'function settings() view returns (address)',
      'function shouldMax() view returns (bool)',
      'function transferOwnership(address)',
      'function underlying() view returns (address)',
      'function votingEscrow() view returns (address)'
    ]
  },
  IPodDepositor: {
    abi: [
      'function ASSET() returns (address)',
      'function COMB() returns (address)',
      'function facilitateMigration(address,uint256)',
      'function feeAndDeposit(address,uint256)',
      'function harvestData() pure returns (uint256, address)',
      'function inCaseEthGetsStuck()',
      'function inCaseTokensGetStuck(address)',
      'function initialize(address)',
      'function migrate(address,uint256) returns (uint256)'
    ]
  },
  Oracle: {
    address: '0xf4766552D15AE4d256Ad41B6cf2933482B0680dc',
    abi: [
      'event AnswerUpdated(int256 indexed,uint256 indexed,uint256)',
      'event NewRound(uint256 indexed,address indexed,uint256)',
      'function getAnswer(uint256) view returns (int256)',
      'function getTimestamp(uint256) view returns (uint256)',
      'function latestAnswer() view returns (int256)',
      'function latestRound() view returns (uint256)',
      'function latestTimestamp() view returns (uint256)'
    ]
  }
}

export default Contracts
