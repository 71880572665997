import { isOnMainnet } from '@/utils/environment'

const DependencyContracts = {
  TSHAREPoolContract: {
    address: isOnMainnet ? '0xcc0a87f7e7c693042a9cc703661f5060c80acb43' : '',
    ABI: [
      {
        inputs: [
          { internalType: 'address', name: '_tshare', type: 'address' },
          { internalType: 'uint256', name: '_poolStartTime', type: 'uint256' }
        ],
        stateMutability: 'nonpayable',
        type: 'constructor'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'uint256',
            name: 'pid',
            type: 'uint256'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'Deposit',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'uint256',
            name: 'pid',
            type: 'uint256'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'EmergencyWithdraw',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'RewardPaid',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'uint256',
            name: 'pid',
            type: 'uint256'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'Withdraw',
        type: 'event'
      },
      {
        inputs: [],
        name: 'TOTAL_REWARDS',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: '_allocPoint', type: 'uint256' },
          { internalType: 'contract IERC20', name: '_token', type: 'address' },
          { internalType: 'bool', name: '_withUpdate', type: 'bool' },
          { internalType: 'uint256', name: '_lastRewardTime', type: 'uint256' }
        ],
        name: 'add',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: '_pid', type: 'uint256' },
          { internalType: 'uint256', name: '_amount', type: 'uint256' }
        ],
        name: 'deposit',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [{ internalType: 'uint256', name: '_pid', type: 'uint256' }],
        name: 'emergencyWithdraw',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: '_fromTime', type: 'uint256' },
          { internalType: 'uint256', name: '_toTime', type: 'uint256' }
        ],
        name: 'getGeneratedReward',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'contract IERC20', name: '_token', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'address', name: 'to', type: 'address' }
        ],
        name: 'governanceRecoverUnsupported',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'massUpdatePools',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'operator',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: '_pid', type: 'uint256' },
          { internalType: 'address', name: '_user', type: 'address' }
        ],
        name: 'pendingShare',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'poolEndTime',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        name: 'poolInfo',
        outputs: [
          { internalType: 'contract IERC20', name: 'token', type: 'address' },
          { internalType: 'uint256', name: 'allocPoint', type: 'uint256' },
          { internalType: 'uint256', name: 'lastRewardTime', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'accTSharePerShare',
            type: 'uint256'
          },
          { internalType: 'bool', name: 'isStarted', type: 'bool' }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'poolStartTime',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'runningTime',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: '_pid', type: 'uint256' },
          { internalType: 'uint256', name: '_allocPoint', type: 'uint256' }
        ],
        name: 'set',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'address', name: '_operator', type: 'address' }
        ],
        name: 'setOperator',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'tSharePerSecond',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'totalAllocPoint',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'tshare',
        outputs: [
          { internalType: 'contract IERC20', name: '', type: 'address' }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [{ internalType: 'uint256', name: '_pid', type: 'uint256' }],
        name: 'updatePool',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: '', type: 'uint256' },
          { internalType: 'address', name: '', type: 'address' }
        ],
        name: 'userInfo',
        outputs: [
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'rewardDebt', type: 'uint256' }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: '_pid', type: 'uint256' },
          { internalType: 'uint256', name: '_amount', type: 'uint256' }
        ],
        name: 'withdraw',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      }
    ]
  },
  TombFtmVaultContract: {
    address: isOnMainnet ? '0x6B9c0b05744bBE6E3d8dF8bEa31A029A12F7C0EB' : '',
    ABI: [
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'owner',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'spender',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'value',
            type: 'uint256'
          }
        ],
        name: 'Approval',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'user',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'total',
            type: 'uint256'
          }
        ],
        name: 'DepositsIncremented',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'implementation',
            type: 'address'
          }
        ],
        name: 'NewStratCandidate',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'previousOwner',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newOwner',
            type: 'address'
          }
        ],
        name: 'OwnershipTransferred',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'user',
            type: 'address'
          }
        ],
        name: 'TermsAccepted',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'from',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'to',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'value',
            type: 'uint256'
          }
        ],
        name: 'Transfer',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'implementation',
            type: 'address'
          }
        ],
        name: 'UpgradeStrat',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'user',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'total',
            type: 'uint256'
          }
        ],
        name: 'WithdrawalsIncremented',
        type: 'event'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'owner',
            type: 'address'
          },
          {
            internalType: 'address',
            name: 'spender',
            type: 'address'
          }
        ],
        name: 'allowance',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'approvalDelay',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'spender',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'approve',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool'
          }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'available',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'balance',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'account',
            type: 'address'
          }
        ],
        name: 'balanceOf',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'constructionTime',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address'
          }
        ],
        name: 'cumulativeDeposits',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address'
          }
        ],
        name: 'cumulativeWithdrawals',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'decimals',
        outputs: [
          {
            internalType: 'uint8',
            name: '',
            type: 'uint8'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'spender',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'subtractedValue',
            type: 'uint256'
          }
        ],
        name: 'decreaseAllowance',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool'
          }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: '_amount',
            type: 'uint256'
          }
        ],
        name: 'deposit',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'depositAll',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'earn',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'getPricePerFullShare',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_token',
            type: 'address'
          }
        ],
        name: 'inCaseTokensGetStuck',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'spender',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'addedValue',
            type: 'uint256'
          }
        ],
        name: 'increaseAllowance',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool'
          }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_token',
            type: 'address'
          },
          {
            internalType: 'string',
            name: '_name',
            type: 'string'
          },
          {
            internalType: 'string',
            name: '_symbol',
            type: 'string'
          },
          {
            internalType: 'uint256',
            name: '_approvalDelay',
            type: 'uint256'
          }
        ],
        name: 'initialize',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_strategy',
            type: 'address'
          }
        ],
        name: 'initializeStrategy',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool'
          }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'initialized',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'name',
        outputs: [
          {
            internalType: 'string',
            name: '',
            type: 'string'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'owner',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_implementation',
            type: 'address'
          }
        ],
        name: 'proposeStrat',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'stratCandidate',
        outputs: [
          {
            internalType: 'address',
            name: 'implementation',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'proposedTime',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'strategy',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'symbol',
        outputs: [
          {
            internalType: 'string',
            name: '',
            type: 'string'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'token',
        outputs: [
          {
            internalType: 'contract IERC20Upgradeable',
            name: '',
            type: 'address'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'totalSupply',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'recipient',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'transfer',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool'
          }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'sender',
            type: 'address'
          },
          {
            internalType: 'address',
            name: 'recipient',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'transferFrom',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool'
          }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'newOwner',
            type: 'address'
          }
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'upgradeStrat',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: '_shares',
            type: 'uint256'
          }
        ],
        name: 'withdraw',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'withdrawAll',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      }
    ]
  },
  erc20: {
    FTM: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    USDC: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    TSHARE: '0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37',
    TOMB: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    _2SHARE: '0xc54A1684fD1bef1f077a336E6be4Bd9a3096a6Ca',
    _2OMB: '0x7a6e4E3CC2ac9924605DCa4bA31d1831c84b44aE',
    ABI: [
      {
        constant: true,
        inputs: [],
        name: 'name',
        outputs: [{ name: '', type: 'string' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
        signature: '0x06fdde03'
      },
      {
        constant: false,
        inputs: [
          { name: 'spender', type: 'address' },
          { name: 'value', type: 'uint256' }
        ],
        name: 'approve',
        outputs: [{ name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
        signature: '0x095ea7b3'
      },
      {
        constant: true,
        inputs: [],
        name: 'totalSupply',
        outputs: [{ name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
        signature: '0x18160ddd'
      },
      {
        constant: false,
        inputs: [
          { name: 'from', type: 'address' },
          { name: 'to', type: 'address' },
          {
            name: 'value',
            type: 'uint256'
          }
        ],
        name: 'transferFrom',
        outputs: [{ name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
        signature: '0x23b872dd'
      },
      {
        constant: true,
        inputs: [],
        name: 'decimals',
        outputs: [{ name: '', type: 'uint8' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
        signature: '0x313ce567'
      },
      {
        constant: false,
        inputs: [
          { name: 'spender', type: 'address' },
          { name: 'addedValue', type: 'uint256' }
        ],
        name: 'increaseAllowance',
        outputs: [{ name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
        signature: '0x39509351'
      },
      {
        constant: false,
        inputs: [
          { name: 'to', type: 'address' },
          { name: 'value', type: 'uint256' }
        ],
        name: 'mint',
        outputs: [{ name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
        signature: '0x40c10f19'
      },
      {
        constant: false,
        inputs: [{ name: 'value', type: 'uint256' }],
        name: 'burn',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
        signature: '0x42966c68'
      },
      {
        constant: true,
        inputs: [{ name: 'owner', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
        signature: '0x70a08231'
      },
      {
        constant: false,
        inputs: [
          { name: 'from', type: 'address' },
          { name: 'value', type: 'uint256' }
        ],
        name: 'burnFrom',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
        signature: '0x79cc6790'
      },
      {
        constant: true,
        inputs: [],
        name: 'symbol',
        outputs: [{ name: '', type: 'string' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
        signature: '0x95d89b41'
      },
      {
        constant: false,
        inputs: [{ name: 'account', type: 'address' }],
        name: 'addMinter',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
        signature: '0x983b2d56'
      },
      {
        constant: false,
        inputs: [],
        name: 'renounceMinter',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
        signature: '0x98650275'
      },
      {
        constant: false,
        inputs: [
          { name: 'spender', type: 'address' },
          { name: 'subtractedValue', type: 'uint256' }
        ],
        name: 'decreaseAllowance',
        outputs: [{ name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
        signature: '0xa457c2d7'
      },
      {
        constant: false,
        inputs: [
          { name: 'to', type: 'address' },
          { name: 'value', type: 'uint256' }
        ],
        name: 'transfer',
        outputs: [{ name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
        signature: '0xa9059cbb'
      },
      {
        constant: true,
        inputs: [{ name: 'account', type: 'address' }],
        name: 'isMinter',
        outputs: [{ name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
        signature: '0xaa271e1a'
      },
      {
        constant: true,
        inputs: [
          { name: 'owner', type: 'address' },
          { name: 'spender', type: 'address' }
        ],
        name: 'allowance',
        outputs: [{ name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
        signature: '0xdd62ed3e'
      },
      {
        inputs: [
          { name: '_name', type: 'string' },
          {
            name: '_symbol',
            type: 'string'
          },
          { name: '_decimals', type: 'uint8' }
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'constructor',
        signature: 'constructor'
      },
      {
        anonymous: false,
        inputs: [{ indexed: true, name: 'account', type: 'address' }],
        name: 'MinterAdded',
        type: 'event',
        signature:
          '0x6ae172837ea30b801fbfcdd4108aa1d5bf8ff775444fd70256b44e6bf3dfc3f6'
      },
      {
        anonymous: false,
        inputs: [{ indexed: true, name: 'account', type: 'address' }],
        name: 'MinterRemoved',
        type: 'event',
        signature:
          '0xe94479a9f7e1952cc78f2d6baab678adc1b772d936c6583def489e524cb66692'
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, name: 'from', type: 'address' },
          {
            indexed: true,
            name: 'to',
            type: 'address'
          },
          { indexed: false, name: 'value', type: 'uint256' }
        ],
        name: 'Transfer',
        type: 'event',
        signature:
          '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef'
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, name: 'owner', type: 'address' },
          {
            indexed: true,
            name: 'spender',
            type: 'address'
          },
          { indexed: false, name: 'value', type: 'uint256' }
        ],
        name: 'Approval',
        type: 'event',
        signature:
          '0x8c5be1e5ebec7d5bd14f71427d1e84f3dd0314c0f7b2291e5b200ac8c7c3b925'
      }
    ]
  },
  tokenPair: {
    TOMB_FTM: '0x2a651563c9d3af67ae0388a5c8f89b867038089e',
    _2OMB_FTM: '0xbdC7DFb7B88183e87f003ca6B5a2F81202343478',
    ABI: [
      {
        inputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'constructor'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'owner',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'spender',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'value',
            type: 'uint256'
          }
        ],
        name: 'Approval',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'sender',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'to',
            type: 'address'
          }
        ],
        name: 'Burn',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'sender',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256'
          }
        ],
        name: 'Mint',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'sender',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount0In',
            type: 'uint256'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount1In',
            type: 'uint256'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount0Out',
            type: 'uint256'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount1Out',
            type: 'uint256'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'to',
            type: 'address'
          }
        ],
        name: 'Swap',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'uint112',
            name: 'reserve0',
            type: 'uint112'
          },
          {
            indexed: false,
            internalType: 'uint112',
            name: 'reserve1',
            type: 'uint112'
          }
        ],
        name: 'Sync',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'from',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'to',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'value',
            type: 'uint256'
          }
        ],
        name: 'Transfer',
        type: 'event'
      },
      {
        constant: true,
        inputs: [],
        name: 'DOMAIN_SEPARATOR',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'MINIMUM_LIQUIDITY',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'PERMIT_TYPEHASH',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: true,
        inputs: [
          { internalType: 'address', name: '', type: 'address' },
          {
            internalType: 'address',
            name: '',
            type: 'address'
          }
        ],
        name: 'allowance',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: 'spender', type: 'address' },
          {
            internalType: 'uint256',
            name: 'value',
            type: 'uint256'
          }
        ],
        name: 'approve',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: false,
        inputs: [{ internalType: 'address', name: 'to', type: 'address' }],
        name: 'burn',
        outputs: [
          { internalType: 'uint256', name: 'amount0', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256'
          }
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'decimals',
        outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'factory',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'getReserves',
        outputs: [
          { internalType: 'uint112', name: '_reserve0', type: 'uint112' },
          {
            internalType: 'uint112',
            name: '_reserve1',
            type: 'uint112'
          },
          {
            internalType: 'uint32',
            name: '_blockTimestampLast',
            type: 'uint32'
          }
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: '_token0', type: 'address' },
          {
            internalType: 'address',
            name: '_token1',
            type: 'address'
          }
        ],
        name: 'initialize',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'kLast',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: false,
        inputs: [{ internalType: 'address', name: 'to', type: 'address' }],
        name: 'mint',
        outputs: [
          { internalType: 'uint256', name: 'liquidity', type: 'uint256' }
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'name',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'nonces',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: 'owner', type: 'address' },
          {
            internalType: 'address',
            name: 'spender',
            type: 'address'
          },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'deadline',
            type: 'uint256'
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          {
            internalType: 'bytes32',
            name: 'r',
            type: 'bytes32'
          },
          { internalType: 'bytes32', name: 's', type: 'bytes32' }
        ],
        name: 'permit',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'price0CumulativeLast',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'price1CumulativeLast',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: false,
        inputs: [{ internalType: 'address', name: 'to', type: 'address' }],
        name: 'skim',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        constant: false,
        inputs: [
          { internalType: 'uint256', name: 'amount0Out', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amount1Out',
            type: 'uint256'
          },
          { internalType: 'address', name: 'to', type: 'address' },
          {
            internalType: 'bytes',
            name: 'data',
            type: 'bytes'
          }
        ],
        name: 'swap',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'symbol',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: false,
        inputs: [],
        name: 'sync',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'token0',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'token1',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'totalSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: 'to', type: 'address' },
          {
            internalType: 'uint256',
            name: 'value',
            type: 'uint256'
          }
        ],
        name: 'transfer',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: 'from', type: 'address' },
          {
            internalType: 'address',
            name: 'to',
            type: 'address'
          },
          { internalType: 'uint256', name: 'value', type: 'uint256' }
        ],
        name: 'transferFrom',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      }
    ]
  },
  spiritswap: {
    address: '0x16327E3FbDaCA3bcF7E38F5Af2599D2DDc33aE52',
    ABI: [
      {
        inputs: [
          {
            internalType: 'address',
            name: '_factory',
            type: 'address'
          },
          { internalType: 'address', name: '_WETH', type: 'address' }
        ],
        stateMutability: 'nonpayable',
        type: 'constructor'
      },
      {
        inputs: [],
        name: 'WETH',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'address', name: 'tokenA', type: 'address' },
          {
            internalType: 'address',
            name: 'tokenB',
            type: 'address'
          },
          { internalType: 'uint256', name: 'amountADesired', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountBDesired',
            type: 'uint256'
          },
          { internalType: 'uint256', name: 'amountAMin', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountBMin',
            type: 'uint256'
          },
          { internalType: 'address', name: 'to', type: 'address' },
          {
            internalType: 'uint256',
            name: 'deadline',
            type: 'uint256'
          }
        ],
        name: 'addLiquidity',
        outputs: [
          { internalType: 'uint256', name: 'amountA', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountB',
            type: 'uint256'
          },
          { internalType: 'uint256', name: 'liquidity', type: 'uint256' }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'address', name: 'token', type: 'address' },
          {
            internalType: 'uint256',
            name: 'amountTokenDesired',
            type: 'uint256'
          },
          { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountETHMin',
            type: 'uint256'
          },
          { internalType: 'address', name: 'to', type: 'address' },
          {
            internalType: 'uint256',
            name: 'deadline',
            type: 'uint256'
          }
        ],
        name: 'addLiquidityETH',
        outputs: [
          { internalType: 'uint256', name: 'amountToken', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountETH',
            type: 'uint256'
          },
          { internalType: 'uint256', name: 'liquidity', type: 'uint256' }
        ],
        stateMutability: 'payable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'factory',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'reserveIn',
            type: 'uint256'
          },
          { internalType: 'uint256', name: 'reserveOut', type: 'uint256' }
        ],
        name: 'getAmountIn',
        outputs: [
          { internalType: 'uint256', name: 'amountIn', type: 'uint256' }
        ],
        stateMutability: 'pure',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'reserveIn',
            type: 'uint256'
          },
          { internalType: 'uint256', name: 'reserveOut', type: 'uint256' }
        ],
        name: 'getAmountOut',
        outputs: [
          { internalType: 'uint256', name: 'amountOut', type: 'uint256' }
        ],
        stateMutability: 'pure',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
          {
            internalType: 'address[]',
            name: 'path',
            type: 'address[]'
          }
        ],
        name: 'getAmountsIn',
        outputs: [
          { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
          {
            internalType: 'address[]',
            name: 'path',
            type: 'address[]'
          }
        ],
        name: 'getAmountsOut',
        outputs: [
          { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'amountA', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'reserveA',
            type: 'uint256'
          },
          { internalType: 'uint256', name: 'reserveB', type: 'uint256' }
        ],
        name: 'quote',
        outputs: [
          { internalType: 'uint256', name: 'amountB', type: 'uint256' }
        ],
        stateMutability: 'pure',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'address', name: 'tokenA', type: 'address' },
          {
            internalType: 'address',
            name: 'tokenB',
            type: 'address'
          },
          { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountAMin',
            type: 'uint256'
          },
          { internalType: 'uint256', name: 'amountBMin', type: 'uint256' },
          {
            internalType: 'address',
            name: 'to',
            type: 'address'
          },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' }
        ],
        name: 'removeLiquidity',
        outputs: [
          { internalType: 'uint256', name: 'amountA', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountB',
            type: 'uint256'
          }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'address', name: 'token', type: 'address' },
          {
            internalType: 'uint256',
            name: 'liquidity',
            type: 'uint256'
          },
          { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountETHMin',
            type: 'uint256'
          },
          { internalType: 'address', name: 'to', type: 'address' },
          {
            internalType: 'uint256',
            name: 'deadline',
            type: 'uint256'
          }
        ],
        name: 'removeLiquidityETH',
        outputs: [
          { internalType: 'uint256', name: 'amountToken', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountETH',
            type: 'uint256'
          }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'address', name: 'token', type: 'address' },
          {
            internalType: 'uint256',
            name: 'liquidity',
            type: 'uint256'
          },
          { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountETHMin',
            type: 'uint256'
          },
          { internalType: 'address', name: 'to', type: 'address' },
          {
            internalType: 'uint256',
            name: 'deadline',
            type: 'uint256'
          }
        ],
        name: 'removeLiquidityETHSupportingFeeOnTransferTokens',
        outputs: [
          { internalType: 'uint256', name: 'amountETH', type: 'uint256' }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'address', name: 'token', type: 'address' },
          {
            internalType: 'uint256',
            name: 'liquidity',
            type: 'uint256'
          },
          { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountETHMin',
            type: 'uint256'
          },
          { internalType: 'address', name: 'to', type: 'address' },
          {
            internalType: 'uint256',
            name: 'deadline',
            type: 'uint256'
          },
          { internalType: 'bool', name: 'approveMax', type: 'bool' },
          {
            internalType: 'uint8',
            name: 'v',
            type: 'uint8'
          },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          {
            internalType: 'bytes32',
            name: 's',
            type: 'bytes32'
          }
        ],
        name: 'removeLiquidityETHWithPermit',
        outputs: [
          { internalType: 'uint256', name: 'amountToken', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountETH',
            type: 'uint256'
          }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'address', name: 'token', type: 'address' },
          {
            internalType: 'uint256',
            name: 'liquidity',
            type: 'uint256'
          },
          { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountETHMin',
            type: 'uint256'
          },
          { internalType: 'address', name: 'to', type: 'address' },
          {
            internalType: 'uint256',
            name: 'deadline',
            type: 'uint256'
          },
          { internalType: 'bool', name: 'approveMax', type: 'bool' },
          {
            internalType: 'uint8',
            name: 'v',
            type: 'uint8'
          },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          {
            internalType: 'bytes32',
            name: 's',
            type: 'bytes32'
          }
        ],
        name: 'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens',
        outputs: [
          { internalType: 'uint256', name: 'amountETH', type: 'uint256' }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'address', name: 'tokenA', type: 'address' },
          {
            internalType: 'address',
            name: 'tokenB',
            type: 'address'
          },
          { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountAMin',
            type: 'uint256'
          },
          { internalType: 'uint256', name: 'amountBMin', type: 'uint256' },
          {
            internalType: 'address',
            name: 'to',
            type: 'address'
          },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' },
          {
            internalType: 'bool',
            name: 'approveMax',
            type: 'bool'
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          {
            internalType: 'bytes32',
            name: 'r',
            type: 'bytes32'
          },
          { internalType: 'bytes32', name: 's', type: 'bytes32' }
        ],
        name: 'removeLiquidityWithPermit',
        outputs: [
          { internalType: 'uint256', name: 'amountA', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountB',
            type: 'uint256'
          }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
          {
            internalType: 'address[]',
            name: 'path',
            type: 'address[]'
          },
          { internalType: 'address', name: 'to', type: 'address' },
          {
            internalType: 'uint256',
            name: 'deadline',
            type: 'uint256'
          }
        ],
        name: 'swapETHForExactTokens',
        outputs: [
          { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }
        ],
        stateMutability: 'payable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'amountOutMin',
            type: 'uint256'
          },
          { internalType: 'address[]', name: 'path', type: 'address[]' },
          {
            internalType: 'address',
            name: 'to',
            type: 'address'
          },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' }
        ],
        name: 'swapExactETHForTokens',
        outputs: [
          { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }
        ],
        stateMutability: 'payable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'amountOutMin',
            type: 'uint256'
          },
          { internalType: 'address[]', name: 'path', type: 'address[]' },
          {
            internalType: 'address',
            name: 'to',
            type: 'address'
          },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' }
        ],
        name: 'swapExactETHForTokensSupportingFeeOnTransferTokens',
        outputs: [],
        stateMutability: 'payable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountOutMin',
            type: 'uint256'
          },
          { internalType: 'address[]', name: 'path', type: 'address[]' },
          {
            internalType: 'address',
            name: 'to',
            type: 'address'
          },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' }
        ],
        name: 'swapExactTokensForETH',
        outputs: [
          { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountOutMin',
            type: 'uint256'
          },
          { internalType: 'address[]', name: 'path', type: 'address[]' },
          {
            internalType: 'address',
            name: 'to',
            type: 'address'
          },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' }
        ],
        name: 'swapExactTokensForETHSupportingFeeOnTransferTokens',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountOutMin',
            type: 'uint256'
          },
          { internalType: 'address[]', name: 'path', type: 'address[]' },
          {
            internalType: 'address',
            name: 'to',
            type: 'address'
          },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' }
        ],
        name: 'swapExactTokensForTokens',
        outputs: [
          { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountOutMin',
            type: 'uint256'
          },
          { internalType: 'address[]', name: 'path', type: 'address[]' },
          {
            internalType: 'address',
            name: 'to',
            type: 'address'
          },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' }
        ],
        name: 'swapExactTokensForTokensSupportingFeeOnTransferTokens',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountInMax',
            type: 'uint256'
          },
          { internalType: 'address[]', name: 'path', type: 'address[]' },
          {
            internalType: 'address',
            name: 'to',
            type: 'address'
          },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' }
        ],
        name: 'swapTokensForExactETH',
        outputs: [
          { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'amountInMax',
            type: 'uint256'
          },
          { internalType: 'address[]', name: 'path', type: 'address[]' },
          {
            internalType: 'address',
            name: 'to',
            type: 'address'
          },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' }
        ],
        name: 'swapTokensForExactTokens',
        outputs: [
          { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      { stateMutability: 'payable', type: 'receive' }
    ]
  },
  uniswap_factory: {
    spooky: '0x152ee697f2e276fa89e96742e9bb9ab1f2e61be3',
    spirit: '0xef45d134b73241eda7703fa787148d9c9f4950b0',
    ABI: [
      {
        inputs: [
          { internalType: 'address', name: '_feeToSetter', type: 'address' },
          { internalType: 'bool', name: '_locked', type: 'bool' }
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'constructor'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'token0',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'token1',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'address',
            name: 'pair',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        name: 'PairCreated',
        type: 'event'
      },
      {
        constant: true,
        inputs: [],
        name: 'INIT_CODE_PAIR_HASH',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: true,
        inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        name: 'allPairs',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'allPairsLength',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: 'tokenA', type: 'address' },
          { internalType: 'address', name: 'tokenB', type: 'address' }
        ],
        name: 'createPair',
        outputs: [{ internalType: 'address', name: 'pair', type: 'address' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'feeTo',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'feeToSetter',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: true,
        inputs: [
          { internalType: 'address', name: '', type: 'address' },
          { internalType: 'address', name: '', type: 'address' }
        ],
        name: 'getPair',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'locked',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'view',
        type: 'function'
      },
      {
        constant: false,
        inputs: [{ internalType: 'address', name: '_feeTo', type: 'address' }],
        name: 'setFeeTo',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: '_feeToSetter', type: 'address' }
        ],
        name: 'setFeeToSetter',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        constant: false,
        inputs: [{ internalType: 'bool', name: '_locked', type: 'bool' }],
        name: 'setLocked',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      }
    ]
  }
}

export default DependencyContracts
