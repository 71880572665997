export const WarContracts = {
  BearBeetsManager: {
    address: '0x71c98E58a19e08D9F4917D29634398F6FDac6ED8',
    ABI: [
      'constructor()',
      'event RoleAdminChanged(bytes32 indexed role, bytes32 indexed previousAdminRole, bytes32 indexed newAdminRole)',
      'event RoleGranted(bytes32 indexed role, address indexed account, address indexed sender)',
      'event RoleRevoked(bytes32 indexed role, address indexed account, address indexed sender)',
      'function DEFAULT_ADMIN_ROLE() view returns (bytes32)',
      'function bar() view returns (address)',
      'function bearBeets() view returns (address)',
      'function deposit(uint256 _amount)',
      'function depositAll()',
      'function getRoleAdmin(bytes32 role) view returns (bytes32)',
      'function grantRole(bytes32 role, address account)',
      'function hasRole(bytes32 role, address account) view returns (bool)',
      'function inCaseTokensGetStuck(address _token)',
      'function initialize(address _bearBeets)',
      'function renounceRole(bytes32 role, address account)',
      'function revokeRole(bytes32 role, address account)',
      'function setDelegate(bytes32 _id, address _delegate)',
      'function snapshot() view returns (address)',
      'function supportsInterface(bytes4 interfaceId) view returns (bool)'
    ]
  }
}

export default WarContracts
